import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat, capitalize } from '../helpers/GlobalHelper';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

// Define a type for the sales data
interface LedgerData {
    idAccVouchers: Number;
    voucherNo: string;
    voucherType: string;
    voucherDate: string;
    narration: string;
    drCrType: string;
    transactionType: string;
    drAmount: any;
    crAmount: any;
    voucher_rows: any;
};

interface LedgerStatementReportProps {

}

const LedgerStatementReport: React.FC<LedgerStatementReportProps> = (props) => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [loading, setLoading] = useState(false);
    const [ledgerData, setLedgerData] = useState<LedgerData[]>([]);

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const [ledgers, setLedgers] = useState([]);
    const [ledger, setLedger] = useState({ label: 'Select Ledger', value: '' });

    const [formData, setFormData] = useState({
        idProjects: query.get('idProjects') ?? '',
        idAccLedgers: query.get('idAccLedgers') ?? '',
        fromDate: query.get('fromDate') ?? '',
        toDate: query.get('toDate') ?? '',
    });

    useEffect(() => {
        getProjects();
        getLedgers();
    }, [])

    useEffect(() => {
        if (projects.length > 0) {
            const projectObj = Number(query.get('idProjects')) 
                ? projects.find((project: any) => project.value === Number(query.get('idProjects'))) 
                : projects[0];
            
            setProject({
                label: projectObj?.label,
                value: projectObj?.value
            });
    
            setFormData(prevFormData => ({
                ...prevFormData,
                idProjects: projectObj ? projectObj.value : '',
            }));
        } else {
            setProject({ label: 'Select Project', value: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]); // Only depend on projects
    
    useEffect(() => {
        if (ledgers.length > 0) {
            const ledgerObj = ledgers.find((ledg: any) => ledg.value === Number(formData.idAccLedgers));
            if (ledgerObj) setLedger({ label: ledgerObj.label, value: ledgerObj.value });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ledgers, formData.idAccLedgers]); // Only depend on ledgers and formData.idAccLedgers
    
    useEffect(() => {
        if (formData.fromDate && formData.toDate) {
            handleViewReport();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]); // Only depend on formData

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getLedgers = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'ledgers',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let ledgerObj = resp.data.map((item: any) => {
                    item.label = item.ledgerName;
                    item.value = item.idAccLedgers;
                    return item
                })
                setLedgers(ledgerObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        if (project) {
            setFormData({ ...formData, idProjects: project.value });
        } else {
            setFormData({ ...formData, idProjects: '' });
        }
    }
    const handleLedgerChange = (ledger: any) => {
        setLedger(ledger);
        if (ledger) {
            setFormData({ ...formData, idAccLedgers: ledger.value });
        } else {
            setFormData({ ...formData, idAccLedgers: '' });
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = async () => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/ledger-statement', postRequestOptions(formData));
            const data = await response.json();

            if (data.success) {
                setLedgerData(data.data);
            } else {
                setLedgerData([]);
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const totalDrAmount = ledgerData.reduce((total, item) => total + Number(item.drAmount), 0);
    const totalCrAmount = ledgerData.reduce((total, item) => total + Number(item.crAmount), 0);


    const queryParams = new URLSearchParams(formData);

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="ledger">
                                    <Form.Label>Ledger<span className='required'></span></Form.Label>
                                    <Select
                                        value={ledger}
                                        onChange={handleLedgerChange}
                                        options={ledgers}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date</Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' value={formData.fromDate} onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date</Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' value={formData.toDate} onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary p-3 mb-2 me-2" href={`${process.env.REACT_APP_BACKEND}ledger_statement_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Download</a>
                                    <a className="btn btn-sm btn-primary p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}ledger_statement_report_print?` + queryParams.toString()}><i className='fas fa-print'></i>Print</a>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th>SN.</th>
                                <th> Date </th>
                                <th>Voucher No</th>
                                <th>Voucher Type</th>
                                <th>Particulars</th>
                                <th className="text-end">Dr. Amount </th>
                                <th className="text-end">Cr. Amount </th>
                                <th className="text-end">Balance </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ledgerData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.voucherDate}</td>
                                    <td>
                                        <Link target='_blank' className="btn btn-light-primary btn-sm" to={`transaction-details/${item.idAccVouchers}`}>
                                            {item.voucherNo}
                                        </Link>
                                    </td>
                                    <td>{capitalize(item.voucherType)}</td>
                                    <td>
                                        {item.transactionType === 'opening' ? (
                                            <span>Opening Balance</span>
                                        ) : (
                                            item.voucher_rows?.length > 0 && item.voucher_rows.map((row: any, rowIdx: any) => (
                                                <div key={rowIdx}>
                                                    <span className="p2">{row?.ledger?.ledgerName} </span>
                                                    <span className="text-right"><strong> {row.dr_amount > 0 ? numberFormat(row.dr_amount) : numberFormat(row.cr_amount)} {(row.drcr === 'dr') ? 'Dr' : 'Cr'}</strong></span>
                                                    <br />
                                                </div>
                                            ))
                                        )}

                                        <small>{item.narration}</small>
                                    </td>
                                    <td className="text-end">{item.drAmount ? numberFormat(item.drAmount) : '0.00'}</td>
                                    <td className="text-end">{item.crAmount ? numberFormat(item.crAmount) : '0.00'}</td>
                                    <td className="text-end">{numberFormat(item.balance)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={5} className="text-end"><strong>Total</strong></td>
                                <td className="text-end"><strong>{numberFormat(totalDrAmount)}</strong></td>
                                <td className="text-end"><strong>{numberFormat(totalCrAmount)}</strong></td>
                                <td className="text-end"></td>
                            </tr>
                        </tfoot>

                    </Table>
                </div>
            </div>
        </>
    );
};

export default LedgerStatementReport;