/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import { Row, Col } from 'react-bootstrap-v5';
import { iconArray } from '../components/LeadActivities';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import Swal from 'sweetalert2'
import showLoader from "../helpers/ShowLoader"
import DataTable from './DataTable'
import Select from 'react-select';


interface ScheduledEventsType {
	activityType: string
	activityDescription: string
	activityAt: string
	id_leads: string
	lead: any
	id_lead_activities: number
}

const DashboardSr = () => {

	const [dateRange, setDateRange] = useState('');
	const [selectProjectOptions, setSelectProjectOptions] = useState([])
	const [project, setProject] = useState({ label: 'Select Project', value: '' });

	const [dashboardData, setDashboardData] = useState({
		leadsAssigned: 0,
		leadsReached: 0,
		leadsYetToReach: 0,
		salesOrderAmount: 0,
		shipmentAmount: 0,
		paymentAmount: 0,
		scheduledEvents: [] as ScheduledEventsType[],
	});

	useEffect(() => {
		getProjectList()
		getSrDashboard()
	}, [])

	useEffect(() => {
		getSrDashboard()
	}, [dateRange, project])

	const handleDateRangeChangeChange = (event: any) => {
		setDateRange(event.target.value)
	}

	const handleProjectChange = (project: any) => {
		setProject(project)
	}

	const getProjectList = async () => {
		const url = process.env.REACT_APP_API_URL + 'projects';

		const response = await fetch(url, getRequestOptions());

		const data = await response.json();

		data.data.map((element: any) => {
			element.value = element.id_projects;
			element.label = element.project_name;
			return element;
		})

		var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
		let allProjects = data.data.concat(allProjectsOpt);
		setSelectProjectOptions(allProjects);
	}

	const columns = [
		{
			value: 'eventDescription', title: 'Event', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row: any) => {


				return (
					<>
						<div className='d-flex align-items-center'>
							<div className='symbol symbol-50px me-5'>
								<KTSVG path={iconArray[row.activityType].icon} className='svg-icon-danger svg-icon svg-icon-2'></KTSVG>
							</div>
							<div className='d-flex justify-content-start flex-column'>
								<a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
									{row.eventDescription}
								</a>
							</div>
						</div>
					</>
				);
			})
		},
		{
			value: 'eventTime', title: 'Time', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row: any) => {


				return (
					<>
						{(new Date(row.eventTime)).toDateString()}, {(new Date(row.eventTime)).toLocaleTimeString()}
					</>
				);
			})
		},

		{ value: 'leadName', title: 'Lead', style: { textAlign: 'left' } },
		{ value: 'companyName', title: 'Client', style: { textAlign: 'left' } },
		{
			value: 'priority', title: 'Priority', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row: any) => {
				let labelClass;
				if (row.priority === "high") {
					labelClass = "success";
				} else if (row.priority === "medium") {
					labelClass = "cyan";
				} else {
					labelClass = "warning";
				}

				return (
					<>
						<span style={{ cursor: "pointer" }} className={'w-100 badge badge-' + labelClass} title='Change Lead Priority' onClick={() => changePriority(row)}>{row.priority?.charAt(0).toUpperCase() + row.priority?.slice(1)}</span>
					</>
				);
			})
		},
		{
			value: 'contactPerson', title: 'Contact Person', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row: any) => {


				return (
					<>
						{row.contactPerson}<br></br>{row.contactPersonPhone}
					</>
				);
			})
		},
		{
			value: 'action', title: 'Action', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row: any) => {


				return (
					<>
						<button type='button' className='btn btn-success btn-sm fw-800 me-2' onClick={() => handleActivityStatus(row.idLeadActivities, 'done')}>
							Done
							<KTSVG path='/media/svg/icons/Navigation/Double-check.svg' className='svg-icon-3 ms-2 me-0' />
						</button>
						<button type='button' className='btn btn-danger btn-sm fw-800' onClick={() => handleActivityStatus(row.idLeadActivities, 'cancelled')}>
							Cancel
							<KTSVG path='/media/svg/icons/Code/Error-circle.svg' className='svg-icon-3 ms-2 me-0' />
						</button>
					</>
				);
			})
		},
	]

	const filters = {
		eventDescription: {
			filterType: 'like',
			filterValue: '',
		},
		eventTime: {
			filterType: 'like',
			filterValue: '',
		},
		leadName: {
			filterType: 'like',
			filterValue: '',
		},
		companyName: {
			filterType: 'like',
			filterValue: '',
		},
		priority: {
			filterType: 'like',
			filterValue: '',
		},
		contactPerson: {
			filterType: 'like',
			filterValue: '',
		},
	}

	const additionalPostData = {
		dateRange: dateRange,
	};

	const url = process.env.REACT_APP_API_URL + 'sr/dashboard/events'

	const getSrDashboard = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'sr/dashboard?idProjects=' + project.value + '&dateRange=' + dateRange,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				// console.log(resp, 'resp.data')
				setDashboardData({
					...dashboardData,
					leadsAssigned: resp.leadsAssigned,
					leadsReached: resp.leadsReached,
					leadsYetToReach: resp.leadsYetToReach,
					salesOrderAmount: resp.salesOrderAmount,
					shipmentAmount: resp.shipmentAmount,
					paymentAmount: resp.paymentAmount,
					scheduledEvents: resp.scheduledEvents,
				});
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const handleActivityStatus = async (idLeadActivities: number, status: string) => {

		if (status === 'done') {
			Swal.fire({
				title: 'Outcome / Discussion',
				input: 'textarea',
				inputAttributes: {
					autocapitalize: 'off',
					placeholder: 'Meeting minutes, discussion points, email body etc.'
				},
				showCancelButton: true,
				confirmButtonText: 'Submit',
				showLoaderOnConfirm: true,
				preConfirm: (content) => {
					let postData = { idLeadActivities: idLeadActivities, status: status, content: content };
					updateActivityStatus(postData);
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log("result.value = ", result.value);
				}
			})
		} else {
			updateActivityStatus({ idLeadActivities: idLeadActivities, status: status });
		}


	}

	const updateActivityStatus = async (postData: any) => {
		const requestOptions = postRequestOptions(postData);
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}sr/dashboard/update-activity-status`,
				requestOptions
			)
			let respData = await response.json()
			Swal.close();

			if (respData.success === true) {
				getSrDashboard();
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: respData.errorMessage,
				})
			}

		} catch (error) {
			Swal.close();
		}
	}

	const changePriority = (rowValue: any) => {

		Swal.fire({
			title: 'Change Lead Priority',
			input: 'select',
			inputAttributes: {
				autocapitalize: 'off',
				placeholder: 'Select Priority',
				defaultValue: rowValue.priority,
			},
			inputOptions: {
				High: 'High',
				Medium: 'Medium',
				Low: 'Low',
			},
			inputValue: rowValue.priority,
			showCancelButton: true,
			confirmButtonText: 'Submit',
			showLoaderOnConfirm: true,
			preConfirm: (content: any) => {
				let postData = { idLeads: rowValue.idLeads, priority: content };
				// console.log(postData);
				updatePriority(postData);
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("result.value=", result.value);
			}
		})
	}

	const updatePriority = async (postData: any) => {
		showLoader();
		const requestOptions = postRequestOptions(postData);
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}lead/update-priority`,
				requestOptions
			)
			let respData = await response.json()
			Swal.close();

			if (respData.success === true) {
				getSrDashboard();
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: respData.errorMessage,
				})
			}

		} catch (error) {
			Swal.close();
		}
	}

	const convertToBDAmount = (label: any) => {
		let labelVal = Number(label);

		if (label < 0) {
			labelVal = label * -1;
		}

		if (labelVal > 10000000) {
			labelVal = (labelVal / 10000000).toFixed(2) + ' Cr';
		}
		if (labelVal > 100000) {
			labelVal = (labelVal / 100000).toFixed(2) + ' Lac';
		}
		if (labelVal > 1000) {
			labelVal = (labelVal / 1000).toFixed(2) + ' K';
		}
		if (label < 0) {
			return '-' + labelVal;
		}
		return labelVal;
	}

	return (
		<>
			<div style={{ background: "none" }} className='card card-custom'>
				<div className='card-header card-header-stretch overflow-auto'>
					<div className='card-title'>
						<h3 className='card-label'>SR Dashboard</h3>
					</div>
					<div className="card-toolbar">
						<div className='d-flex justify-content-end'>
							<div className='mt-5 mb-2'>
								<select className='form-control' onChange={(event) => handleDateRangeChangeChange(event)}>
									<option value={''}>All Date</option>
									<option value={'last_7_days'}>Last 7 Days</option>
									<option value={'last_month'}>Last Month</option>
									<option value={'last_quarter'}>Last Quarter</option>
									<option value={'last_year'}>Last Year</option>
								</select>
							</div>
							&nbsp;&nbsp;&nbsp;
							<div className='mt-5 mb-2'>
								<Select
									defaultValue={selectProjectOptions}
									onChange={handleProjectChange}
									options={selectProjectOptions}
									placeholder="Select project"
									menuPosition="fixed"
									styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="row  gy-5 g-xl-2">
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100">
								<div className="card-body d-flex justify-content-between align-items-start flex-column">
									{/* <div className="m-2">
										<KTSVG className='svg-icon-primary svg-icon svg-icon-2' path='/media/svg/icons/General/Thunder-move.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{dashboardData.leadsAssigned}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Leads Assigned </span>
										</div>
									</div>
									{/* <span className="badge badge-light-success fs-base">
										<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>
										2.1%
									</span> */}
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100">
								<div className="card-body d-flex justify-content-between align-items-start flex-column">
									{/* <div className="m-2">
										<KTSVG className='svg-icon-success svg-icon svg-icon-2' path='/media/svg/icons/Communication/Clipboard-check.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{dashboardData.leadsReached}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Leads reached </span>
										</div>
									</div>
									{/* <span className="badge badge-light-success fs-base">
										<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>
										2.1%
									</span> */}
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100">
								<div className="card-body d-flex justify-content-between align-items-start flex-column">
									{/* <div className="m-2">
										<KTSVG className='svg-icon-danger svg-icon svg-icon-2' path='/media/svg/icons/Communication/Clipboard-list.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{dashboardData.leadsYetToReach}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Leads yet to reach </span>
										</div>
									</div>
									{/* <span className="badge badge-light-danger fs-base">
										<i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
										2.1%
									</span> */}
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100">
								<div className="card-body d-flex justify-content-between align-items-start flex-column">
									{/* <div className="m-2">
										<KTSVG className='svg-icon-info svg-icon svg-icon-2' path='/media/svg/icons/Map/Direction2.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.salesOrderAmount)}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Sales Order Amount </span>
										</div>
									</div>
									{/* <span className="badge badge-light-info fs-base">
										<i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
										2.1%
									</span> */}
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100">
								<div className="card-body d-flex justify-content-between align-items-start flex-column">
									{/* <div className="m-2">
										<KTSVG className='svg-icon-success svg-icon svg-icon-2' path='/media/svg/icons/Shopping/Box3.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.shipmentAmount)}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Total Shipment Amount </span>
										</div>
									</div>
									{/* <span className="badge badge-light-success fs-base">
										<i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
										2.1%
									</span> */}
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100">
								<div className="card-body d-flex justify-content-between align-items-start flex-column">
									{/* <div className="m-2">
										<KTSVG className='svg-icon-warning svg-icon svg-icon-2' path='/media/svg/icons/Shopping/Sale1.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.paymentAmount)}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Payment Received </span>
										</div>
									</div>
									{/* <span className="badge badge-light-warning fs-base">
										<i className="ki-outline ki-arrow-up fs-5 text-error ms-n1"></i>
										2.1%
									</span> */}
								</div>
							</div>
						</div>
					</div>
					{/* <pre>{JSON.stringify(dashboardData, null, 2)}</pre> */}

					<Row>
						<Col md={12}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											Scheduled Events
										</span>
									</div>
									<div className="card-toolbar">

									</div>
								</div>

								<div className='card-body'>
									<DataTable url={url} columns={columns} filters={filters} newData={columns} additionalPostData={additionalPostData} />
								</div>
							</div>
						</Col>
					</Row>

					{/* <Row>
						<Col md={12}>
							<div className='card'>
								<div className='card-header border-0 pt-5'>
									<h3 className='card-title align-items-start flex-row'>
										<span className='card-label fw-bold fs-3 mb-1'>Scheduled events</span>
										<span className='text-muted mt-2 fw-semibold fs-8'>{dashboardData?.scheduledEvents?.length} events scheduled</span>
									</h3>
								</div>
								<div className='card-body py-3'>
									<div className='table-responsive'>
										<table className='table table-sm align-middle gs-0 gy-4'>
											<thead>
												<tr className='fw-bold text-muted bg-light'>
													<th style={{ width: '30%' }} className='rounded-start'>Event</th>
													<th style={{ width: '20%' }} className=''>Lead</th>
													<th style={{ width: '10%' }} className='text-center'>Priority</th>
													<th style={{ width: '20%' }} className='text-center'>Contact Person</th>
													<th style={{ width: '20%' }} className=' text-end rounded-end'>Action</th>
												</tr>
											</thead>
											<tbody>
												{
													dashboardData?.scheduledEvents?.map((scheduled, index) => {
														return <>
															<tr key={'scheduled-' + index}>
																<td>
																	<div className='d-flex align-items-center'>
																		<div className='symbol symbol-50px me-5'>
																			<KTSVG path={iconArray[scheduled.activityType].icon} className='svg-icon-danger svg-icon svg-icon-2'></KTSVG>
																		</div>
																		<div className='d-flex justify-content-start flex-column'>
																			<a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
																				{scheduled.activityDescription}
																			</a>
																			<span className='text-muted fw-semibold text-muted d-block fs-7'>
																				{(new Date(scheduled.activityAt)).toDateString()}, {(new Date(scheduled.activityAt)).toLocaleTimeString()}
																			</span>
																		</div>
																	</div>
																</td>
																<td>
																	<a href={"/#/lead-details?idLeads=" + scheduled.id_leads} className='text-dark fw-bold text-hover-primary d-block fs-6'>
																		{scheduled.lead?.leadName}
																	</a>
																	<span className='text-muted fw-semibold text-muted d-block fs-7'>{scheduled.lead?.company?.companyName}</span>
																</td>
																<td>

																	{scheduled.lead?.priority === "high" &&
																		<span style={{ cursor: "pointer" }} className={'w-100 badge badge-success'} title='Change Lead Priority' onClick={() => changePriority(scheduled.lead)}>{scheduled.lead?.priority?.charAt(0).toUpperCase() + scheduled.lead?.priority?.slice(1)}</span>
																	}
																	{scheduled.lead?.priority === "medium" &&
																		<span style={{ cursor: "pointer" }} className={'w-100 badge badge-cyan'} title='Change Lead Priority' onClick={() => changePriority(scheduled.lead)}>{scheduled.lead?.priority?.charAt(0).toUpperCase() + scheduled.lead?.priority?.slice(1)}</span>
																	}
																	{scheduled.lead?.priority === "low" &&
																		<span style={{ cursor: "pointer" }} className={'w-100 badge badge-warning'} title='Change Lead Priority' onClick={() => changePriority(scheduled.lead)}>{scheduled.lead?.priority?.charAt(0).toUpperCase() + scheduled.lead?.priority?.slice(1)}</span>
																	}

																</td>
																<td className='text-center'>
																	<span className='text-dark fw-bold text-hover-primary d-block fs-6'>
																		{scheduled.lead?.contact_person?.name}
																	</span>
																	<span className='text-muted fw-semibold text-muted d-block fs-7'>{scheduled.lead?.contact_person?.phones[0]?.phone_number}</span>
																</td>
																<td className='text-end'>
																	<button type='button' className='btn btn-success btn-sm fw-800 me-2' onClick={() => handleActivityStatus(scheduled.id_lead_activities, 'done')}>
																		Done
																		<KTSVG path='/media/svg/icons/Navigation/Double-check.svg' className='svg-icon-3 ms-2 me-0' />
																	</button>
																	<button type='button' className='btn btn-danger btn-sm fw-800' onClick={() => handleActivityStatus(scheduled.id_lead_activities, 'cancelled')}>
																		Cancel
																		<KTSVG path='/media/svg/icons/Code/Error-circle.svg' className='svg-icon-3 ms-2 me-0' />
																	</button>
																</td>
															</tr>
														</>

													})
												}

												{dashboardData?.scheduledEvents?.length === 0 && <tr><td colSpan={4} className='text-center'><span className='text-muted fw-semibold text-muted d-block fs-7'>Scheduled events not found!</span></td></tr>}

											</tbody>
										</table>
									</div>
								</div>
							</div>
						</Col>
					</Row> */}
				</div>
			</div>

		</>

	)
}

export default DashboardSr
