import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Rows {
    idShipmentRows: Number;
    quantity: Number;
    productName: string;
    idProducts: Number;
    packing: string;
    size: string;
    unitName: string;
}

// Define a type for the sales data
interface DeliveryData {
    idShipments: Number;
    idSalesOrders: Number;
    shipmentDate: string;
    chalanNo: string;
    invoiceId: string;
    companyName: string;
    salesPerson: string;
    remarks: string;
    orderAmount: any;
    rows: Rows[];
};

interface DeliveryReportProps {

}

const DeliveryReport: React.FC<DeliveryReportProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const [deliveryData, setDeliveryData] = useState<DeliveryData[]>([]);
    const [formData, setFormData] = useState({
        idProjects: '',
        idCompanies: '',
        fromDate: '',
        toDate: '',
    });

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState({ label: 'Select Client', value: '' });

    useEffect(() => {
        getProjects();
        getCompanies();
    }, [])

    useEffect(() => {
        if (projects.length > 0) {
            setProject(projects[0]);
            setFormData((prevFormData) => ({
                ...prevFormData,
                idProjects: projects[0].value,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);
    
    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getCompanies = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/companies',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let companiesObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setCompanies(companiesObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        if (project) {
            setFormData({ ...formData, idProjects: project.value });
        } else {
            setFormData({ ...formData, idProjects: '' });
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCompanyChange = (comapny: any) => {
        setCompany(comapny);
        setFormData({ ...formData, idCompanies: comapny?.value });
    }

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    const handleViewReport = async () => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/delivery', postRequestOptions(formData));
            const data = await response.json();

            if (data.success) {
                setDeliveryData(data.data);
            } else {
                setDeliveryData([]);
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const queryParams = new URLSearchParams(formData);

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-2">
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Client<span className="required text-danger"></span></Form.Label>
                                    <Select
                                        value={company}
                                        onChange={handleCompanyChange}
                                        options={companies}
                                        menuPosition="fixed"
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}delivery_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                                </div>
                            </div>
                        </div>

                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th className='text-center'>Date</th>
                                <th className='text-center'>Order Sheet No</th>
                                <th className='text-center'>Challan No</th>
                                <th className='text-center'>Invoice No</th>
                                <th className='text-center'>Party Name</th>
                                <th className='text-center'>Sales Person</th>
                                <th className='text-center'>Item</th>
                                <th className='text-center'>Package</th>
                                <th className='text-center'>Quantity</th>
                                <th className='text-center'>Unit</th>
                                <th className='text-center'>Remarks</th>
                                <th className='text-center'>Order Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryData.length > 0 ? (
                                deliveryData.map((shipment, slIndex) => (
                                    <React.Fragment key={shipment.idShipments}>
                                        {shipment.rows.map((item, index) => (
                                            <tr key={item.idShipmentRows}>
                                                {index === 0 && (
                                                    <>
                                                        <td rowSpan={shipment.rows.length} className='text-center'>
                                                            {shipment.shipmentDate}
                                                        </td>
                                                        <td rowSpan={shipment.rows.length} className='text-center'>
                                                            {shipment.idSalesOrders}
                                                        </td>
                                                        <td rowSpan={shipment.rows.length} className='text-center'>
                                                            {shipment.chalanNo}
                                                        </td>
                                                        <td rowSpan={shipment.rows.length} className='text-center'>
                                                            {shipment.invoiceId}
                                                        </td>
                                                        <td rowSpan={shipment.rows.length} className='text-center'>
                                                            {shipment.companyName}
                                                        </td>
                                                        <td rowSpan={shipment.rows.length} className='text-center'>
                                                            {shipment.salesPerson}
                                                        </td>
                                                    </>
                                                )}
                                                <td>{item.productName}</td>
                                                <td>{item.packing}</td>
                                                <td className='text-end'>{item.quantity}</td>
                                                <td>{item.unitName}</td>
                                                {index === 0 && (
                                                    <>
                                                        <td rowSpan={shipment.rows.length} className='text-end'>
                                                            {shipment.remarks}
                                                        </td>
                                                        <td rowSpan={shipment.rows.length} className='text-end'>
                                                            {numberFormat(shipment.orderAmount)}
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={12} className='text-center'>No Data Found</td>
                                </tr>
                            )}
                        </tbody>

                    </Table>
                </div>
            </div>
        </>
    );
};

export default DeliveryReport;