import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import Dashboard from '../pages/Dashboard';
import PageList from '../pages/PageList';
import PageManagement from '../pages/PageManagement';
import RoleManagement from '../pages/RoleManagement';
import Logout from "../pages/Logout";
import Users from "../pages/Users";
import CompanyCategory from '../pages/CompanyCategoryList';
import SalesQuotation from '../pages/SalesQuotation';
import SalesInvoice from '../pages/SalesInvoice';
import SalesQuotationCreate from '../pages/SalesQuotationCreate';
import SalesInvoiceCreate from '../pages/SalesInvoiceCreate';
import SalesOrderDetails from '../pages/SalesOrderDetails';
import SalesInvoiceDetails from '../pages/SalesInvoiceDetails';
import SalesOrder from '../pages/SalesOrder';
import SalesOrderCreate from '../pages/SalesOrderCreate';
import CompanyList from '../pages/CompanyList';
import CompanyEdit from '../pages/CompanyEdit';
import CompetitorEdit from '../pages/CompetitorEdit';
import GeneralConfiguration from '../pages/GeneralConfiguration';
import EmailTemplate from '../pages/EmailTemplate';
import MyProfile from '../pages/MyProfile';
import UserLog from '../pages/UserLog';
import { Leads } from '../pages/Leads';
import Sales from '../pages/Sales';
import ProjectsManagement from '../pages/ProjectsManagement';
import ManageProducts from '../pages/ManageProducts';
import ManageSrCategory from '../pages/ManageSrCategory';
import ManageItems from '../pages/ManageItems';
import LeadDetails from '../pages/LeadDetails';
import ProjectStageManagement from '../pages/ProjectStageManagement';
import Reports from '../pages/Reports';
import Products from '../pages/Products';
import Projects from '../pages/Projects';
import VisitPlan from '../pages/VisitPlan';
import Activities from '../pages/Activities';
import SrDetails from '../pages/SrDetails';
import Shipment from '../pages/Shipment';
import SalesPayment from '../pages/SalesPayment';
import BankList from '../pages/BankList';
import ChartOfAccounts from '../pages/ManageChartOfAccount';
import CashBankList from '../pages/CashBankList';
import Transactions from '../pages/Transactions';
import TransactionDetails from '../pages/TransactionDetails';
import Expenses from '../pages/Expenses';
import TrialBalance from '../pages/TrialBalance';
import IncomeStatement from '../pages/IncomeStatement';
import CompanyDetails from '../pages/CompanyDetails';
import OverheadList from '../pages/OverheadList';
import ProductionCostSetting from '../pages/ProductionCostSetting';
import VendorList from '../pages/VendorList';
import Purchase from '../pages/Purchase';
import PurchaseCreate from '../pages/PurchaseCreate';
import TargetList from '../pages/TargetList';
import PurchaseDetails from '../pages/PurchaseDetails';
import ManufacturingList from '../pages/ManufacturingList';
import ManufacturingCreate from '../pages/ManufacturingCreate';
import PurchaseEdit from '../pages/PurchaseEdit';
import InventoryStockStatus from '../pages/InventoryStockStatus';
import ManufacturingDetails from '../pages/ManufacturingDetails';
import MergeEntity from '../pages/MergeEntity';
import ItemGlobalRating from '../pages/ItemGlobalRating';

import ItemStockDetails from '../pages/ItemStockDetails';
import FinishedGoodsStockDetails from '../pages/FinishedGoodsStockDetails';

import PurchaseOrder from '../pages/PurchaseOrder';
import PurchaseOrderCreate from '../pages/PurchaseOrderCreate';
import PurchaseOrderDetails from '../pages/PurchaseOrderDetails';

//Opening Entry for Inventory
import InventoryOpening from '../pages/InventoryOpening';
import InventoryOpeningCreate from '../pages/InventoryOpeningCreate';
import InventoryOpeningDetails from '../pages/InventoryOpeningDetails';

//Stock Issue for Inventory
import InventoryStockIssue from '../pages/InventoryStockIssue';
import InventoryStockIssueCreate from '../pages/InventoryStockIssueCreate';
import InventoryStockIssueDetails from '../pages/InventoryStockIssueDetails';

import MapDashboard from '../pages/MapDashboard';

export function PrivateRoutes() {

	return (
		<Suspense fallback={<FallbackView />}>
			<Switch>
				<Route path='/dashboard' component={Dashboard} />
				<Route path='/leads' component={Leads} />
				<Route path='/manage-products' component={ManageProducts} />
				<Route path='/manage-sr-category' component={ManageSrCategory} />
				<Route path='/manage-items' component={ManageItems} />
				<Route path='/products' component={Products} />
				<Route path='/reports' component={Reports} />
				<Route path='/competitor-edit/:id' component={CompetitorEdit} />
				<Route path='/lead-details' component={LeadDetails} />
				<Route path='/sr-details/:id' component={SrDetails} />
				<Route path='/sales' component={Sales} />
				<Route path='/manage-projects' component={ProjectsManagement} />
				<Route path='/projects' component={Projects} />
				<Route path='/sales-quotations' component={SalesQuotation} />
				<Route path='/sales-invoices' component={SalesInvoice} />
				<Route path='/sales-quotation-create' component={SalesQuotationCreate} />
				<Route path='/sales-invoice-create' component={SalesInvoiceCreate} />
				<Route path='/sales-orders' component={SalesOrder} />
				<Route path='/sales-order-create' component={SalesOrderCreate} />
				<Route path='/sales-order/:idSalesOrders' component={SalesOrderDetails} />
				<Route path='/sales-invoice/:idSalesInvoice' component={SalesInvoiceDetails} />
				<Route path='/shipments' component={Shipment} />
				<Route path='/overheads' component={OverheadList} />
				<Route path='/manufacturing' component={ManufacturingList} />
				<Route path='/manufacturing-create' component={ManufacturingCreate} />
				<Route path='/manufacturing-details/:idProduction' component={ManufacturingDetails} />
				<Route path='/manufacturing-edit/:idProductions' component={ManufacturingCreate} />
				<Route path='/sales-payments' component={SalesPayment} />
				<Route path='/setup-category' component={CompanyCategory} />
				<Route path='/setup-company-list' component={CompanyList} />
				<Route path='/setup-company-edit/:id' component={CompanyEdit} />
				<Route path='/setup-general-configuration' component={GeneralConfiguration} />
				<Route path='/setup-email-template' component={EmailTemplate} />
				<Route path='/accesscontrol-users' component={Users} />
				<Route path='/accesscontrol-rolemanagement' component={RoleManagement} />
				<Route path='/accesscontrol-page-list' component={PageList} />
				<Route path='/accesscontrol-pagemanagement' component={PageManagement} />
				<Route path='/project-stage/:idProject' component={ProjectStageManagement} />
				<Route path='/projects' component={Projects} />
				<Route path='/item-global-rating' component={ItemGlobalRating} />
				<Route path="/activityLog-user-log" component={UserLog} />
				<Route path="/logout" component={Logout} />
				<Route path="/my-profile" component={MyProfile} />
				<Route path="/performance-tracking" component={SrDetails} />
				<Route path="/activities" component={Activities} />
				<Route path="/activities" component={Activities} />
				<Route path="/banks" component={BankList} />
				<Route path="/chart-of-accounts" component={ChartOfAccounts} />
				<Route path="/transactions" component={Transactions} />
				<Route path="/transaction-details/:idAccVouchers" component={TransactionDetails} />
				<Route path="/bank-accounts-cash" component={CashBankList} />
				<Route path="/expenses" component={Expenses} />
				<Route path="/trial-balance" component={TrialBalance} />
				<Route path="/company-details/:idCompanies" component={CompanyDetails} />
				<Route path="/production-cost-setting/:id" component={ProductionCostSetting} />
				<Route path='/vendors' component={VendorList} />
				<Route path='/purchase' component={Purchase} />

				<Route path='/purchase-create' component={PurchaseCreate} />
				<Route path='/purchase-details/:idPurchase' component={PurchaseDetails} />
				<Route path='/purchase-edit/:idPurchase' component={PurchaseEdit} />
				<Route path='/stock-status' component={InventoryStockStatus} />
				<Route path='/item-stock-details' component={ItemStockDetails} />
				<Route path='/finished-goods-stock-details' component={FinishedGoodsStockDetails} />
				<Route path='/targets' component={TargetList} />

				<Route path='/po' component={PurchaseOrder} />
				<Route path='/po-create' component={PurchaseOrderCreate} />
				<Route path='/po-details/:idPo' component={PurchaseOrderDetails} />

				<Route path='/inventory-opening' component={InventoryOpening} />
				<Route path='/inventory-opening-create' component={InventoryOpeningCreate} />
				<Route path='/inventory-opening-details/:idInvOpeningStocks' component={InventoryOpeningDetails} />

				<Route path='/stock-issue' component={InventoryStockIssue} />
				<Route path='/stock-issue-create' component={InventoryStockIssueCreate} />
				<Route path='/stock-issue-details/:idInvIssues' component={InventoryStockIssueDetails} />
				<Route path='/merge-entity' component={MergeEntity} />
				<Route path='/visit-plan' component={VisitPlan} />

				<Route path='/map-dashboard' component={MapDashboard} />
				<Route path="/income-statement" component={IncomeStatement} />

				<Redirect exact from='/' to='/dashboard' />
				<Redirect to='error/404' />
			</Switch>
		</Suspense>
	)
}
