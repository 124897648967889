import { Button, Col, Row, Table } from "react-bootstrap-v5";
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from 'react-router-dom';
import showLoader from "../helpers/ShowLoader";
import Swal from "sweetalert2";
import React from 'react';
import {numberFormat } from '../helpers/GlobalHelper';  

interface LedgerData {
    sales: number;
    vatCurrentAccount: number;
    netSales: number;
    cogs: number;
    grossProfit: number;
    adminExpenses: number;
    sellingExpenses: number;
    financialCharges: number;
    operatingProfit: number;
    otherIncome: number;
    netProfitBeforeTax: number;
    incomeTax: number;
    netProfitForYear: number;
    interCompanyInterest: number;
    netProfitAfterInterest: number;
    grossProfitRatio: number;
    cogsRatio: number;
    netProfitRatio: number;
}

export default function IncomeStatement() {

    // Helper function to format a Date object as yyyy-MM-dd
    const formatDate = (date: Date) => {
        // return date.toISOString().split("T")[0];
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero
        const day = date.getDate().toString().padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
    };

    // Get the current date
    const today = new Date();

    // Determine the fiscal start and end dates
    const fiscalYearStart = today.getMonth() < 6 // Before July
    ? new Date(today.getFullYear() - 1, 6, 1) // Previous year July 1st
    : new Date(today.getFullYear(), 6, 1); // Current year July 1st

    const fiscalYearEnd = new Date(fiscalYearStart.getFullYear() + 1, 5, 30); // Next year June 30th

    // Default start and end dates formatted as yyyy-MM-dd
    const defaultStartDate = formatDate(fiscalYearStart);
    const defaultEndDate = formatDate(fiscalYearEnd);

    console.log("Fiscal Year Start:", defaultStartDate); // Output: YYYY-07-01
    console.log("Fiscal Year End:", defaultEndDate);     // Output: YYYY-06-30

    const [incomeStatementData, setIncomeStatement] = useState<LedgerData>({} as LedgerData);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);
    const [startDate, setStartDate] = useState<string | null>(defaultStartDate);
    const [endDate, setEndDate] = useState<string | null>(defaultEndDate);

    useEffect(() => {
        // Check if selectProjectOptions is available before proceeding
        if (selectProjectOptions.length === 0) return;

       // Extract the query string after the '?' from the hash part of the URL
       const hashParams = window.location.hash.split('?')[1];

       // If there are no query parameters, return early
       if (!hashParams) return;
       // Use URLSearchParams to parse the query string
       const urlParams = new URLSearchParams(hashParams);

        // Retrieve individual params
        const projectId = urlParams.get("id_projects");
        const start = urlParams.get("startDate");
        const end = urlParams.get("endDate");

        // Set the state based on retrieved params
        if (projectId) {
            const project = selectProjectOptions.find((opt: any) => opt.value === Number(projectId));
            setSelectedProject(project);
        }else{
            setSelectedProject(selectProjectOptions[0]);
        }


        if (start) {
            setStartDate(start);
        }

        if (end) {
            setEndDate(end);
        }

    }, [selectProjectOptions]);

    useEffect(() => {
        getIncomeStatement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject, startDate, endDate]);
    
    async function getProjectList() {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })
        setSelectProjectOptions(data.data);
        setSelectedProject(selectProjectOptions[0]);
    }

    const handleProjectChange = (project: any) => {
        setSelectedProject(project);
    };

    // Update the URL with the selected project
    if(selectedProject || startDate || endDate) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("id_projects", selectedProject?.value || '');
        urlParams.set("startDate", startDate || '');
        urlParams.set("endDate", endDate || '');
        const newUrl = `${window.location.pathname}#/income-statement?${urlParams.toString()}`;
        window.history.pushState(null, "", newUrl);
    }

    async function getIncomeStatement() {
        showLoader();
        const response = await fetch(process.env.REACT_APP_API_URL + 'reports/income-statement', postRequestOptions(
            {
                idProjects: selectedProject?.value,
                startDate: startDate,
                endDate: endDate
            }
        ))
        const data = await response.json();
        if (data.success) {
            Swal.close();
            setIncomeStatement(data.data);
        } else {
            Swal.close();
            // toast(data.errors, { type: 'error' });
        }
    }

    useEffect(() => {
        getIncomeStatement();
        getProjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const queryParams = new URLSearchParams([
        ['idProjects', selectedProject?.value || ''],
        ['startDate', startDate || ''],
        ['endDate', endDate || '']
    ]);
    

    return <div className='card card-custom'>
        <div className='card-header'>
            <div className="card-title">
                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                    Income Statement
                </span>
            </div>
            <div className='card-toolbar'>
                <div className='d-flex justify-content-end align-items-center'>
                    <Select
                        value={selectedProject}
                        className="form-control-sm h-50px w-250px me-3"
                        defaultValue={selectProjectOptions}
                        onChange={handleProjectChange}
                        options={selectProjectOptions}
                        placeholder="Select project"
                    />
                    <Row>
                        <Col className="d-flex align-items-center text-end pe-0">
                            From:
                        </Col>
                        <Col>
                            <input onChange={e => { setStartDate(e.target.value) }} value={startDate || ""} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                        <Col className="d-flex align-items-center text-end pe-0">
                            To:
                        </Col>
                        <Col>
                            <input onChange={e => { setEndDate(e.target.value) }} value={endDate || ""} type="date" className="d-inline form-control form-control-sm" />
                        </Col>
                    </Row>
                    <Button onClick={() => getIncomeStatement()} variant="primary" className="ms-2 p-2 me-2" size="sm">Refresh</Button>
                    <a className="btn btn-sm btn-primary p-2 ml-2" href={`${process.env.REACT_APP_BACKEND}income_statement_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                    {/* <pre>{JSON.stringify(defaultStartDate, null, 2)}</pre>
                    <pre>{JSON.stringify(defaultEndDate, null, 2)}</pre>
                    <pre>{JSON.stringify(startDate, null, 2)}</pre> */}
                </div>
            </div>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col">
                    <div className="trial-balance-container table-responsive">
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th className="p-2 border text-center" width="50%">Particulars</th>
                                    <th className="p-2 border text-center" width="50%">Year Ended</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-2 border"><strong>Sales</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(incomeStatementData.sales)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">VAT on sales</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.vatCurrentAccount)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Sales: Net of VAT</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(incomeStatementData.salesNet)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">
                                        <Link to={`reports?type=costOfGoodsSoldReport&idProjects=${selectedProject?.value}&fromDate=${startDate}&toDate=${endDate}`}>
                                            Less: Cost of Sales
                                        </Link>
                                    </td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.cogs)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>COGS Ratio</strong></td>
                                    <td className="p-2 border text-end"><strong>{(incomeStatementData.cogsRatio)}%</strong></td>
                                </tr>
                                <tr className="font-semibold bg-gray-100">
                                    <td className="p-2 border"><strong>Gross Profit</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(incomeStatementData.grossProfit)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border"><strong>Gross Profit Ratio</strong></td>
                                    <td className="p-2 border text-end"><strong>{(incomeStatementData.grossProfitRatio)}%</strong></td>
                                </tr>
                                <tr className="font-semibold">
                                    <td className="p-2 border"><strong>Operating Expenses</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(incomeStatementData.operatingExpenses)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border pl-4">Manufacturing overhead</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.manufacturingOverheadExp)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border pl-4">Administration Expenses</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.adminExp)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border pl-4">Selling & Distribution Expenses</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.sellingExp)}</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border pl-4">Financial Charges</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.financialExp)}</td>
                                </tr>
                                <tr className="font-semibold bg-gray-100">
                                    <td className="p-2 border"><strong>Operating Profit</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(incomeStatementData.operatingProfit)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Add: Other Income</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.otherIncome)}</td>
                                </tr>
                                <tr className="font-semibold bg-gray-100">
                                    <td className="p-2 border">Add: Inter Company Interest Income/(Expense)</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.interCompanyInterest)}</td>
                                </tr>
                                <tr className="font-semibold bg-gray-100">
                                    <td className="p-2 border"><strong>Net Profit Before Tax</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(incomeStatementData.netProfitBeforeTax)}</strong></td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">Less: Income Tax Paid</td>
                                    <td className="p-2 border text-end">{numberFormat(incomeStatementData.incomeTax)}</td>
                                </tr>
                                <tr className="font-semibold bg-gray-100">
                                    <td className="p-2 border"><strong>Net Profit(after tax)</strong></td>
                                    <td className="p-2 border text-end"><strong>{numberFormat(incomeStatementData.netProfit)}</strong></td>
                                </tr>
                                <tr className="font-semibold bg-gray-100">
                                    <td className="p-2 border"><strong>Net Profit/Loss Ratio</strong></td>
                                    <td className="p-2 border text-end"><strong>{(incomeStatementData.netProfitRatio)}%</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            
            
        </div>
    </div >
}