/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from 'react'
import { KTSVG } from '../../_metronic/helpers';
import { Row, Col, Table } from 'react-bootstrap-v5';
import { getRequestOptions } from '../helpers/Fetchwrapper';
import DataTable from './DataTable'
import BackendTable from 'react-backend-table';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Chart from "react-apexcharts";

const DashboardUser = () => {

	const [dateRange, setDateRange] = useState('year');
	const [leadType, setLeadType] = useState('');
	const [activityType, setActivityType] = useState('');
	const [customDate, setCustomDate] = useState({ customFromDate: '', customToDate: '' });

	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');

	useEffect(() => {

		if (dateRange === 'custom') {
			setFromDate(customDate.customFromDate);
			setToDate(customDate.customToDate);
		} else if (dateRange === 'year') {
			let date = new Date();
			let year = date.getFullYear();
			setFromDate(year + '-01-01');
			setToDate(year + '-12-31');

		} else if (dateRange === 'month') {
			let date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let lastDayOfMonth = new Date(year, month, 0).getDate();
			let monthStr = month < 10 ? '0' + month : month;

			setFromDate(year + '-' + monthStr + '-01');
			setToDate(year + '-' + monthStr + '-' + lastDayOfMonth);
		}

	}, [dateRange, customDate.customFromDate, customDate.customToDate])

	const handleClickLeads = (param: string) => {
		setLeadType(param);
	};

	const handleDateRangeChange = (event: any) => {
		if (event === 'custom') {
			setDateRange('custom');
		} else {
			setDateRange(event.target.value);
		}
	}

	const handleClickActivity = (param: string) => {
		setActivityType(param);
	};

	const [dashboardData, setDashboardData] = useState({
		leadsCreated: 0,
		highPriorityLeads: 0,
		leadsLastThreeDays: 0,
		totalActivity: 0,
		activityLastSevenDays: 0,
		commentLastSevenDays: 0,
		totalSales: 0,
		totalPayment: 0,
		totalDue: 0,
		invoice: 0,
		topSellingProduct: [],
	});

	useEffect(() => {
		getProjectList()
	}, [])

	const [selectProjectOptions, setSelectProjectOptions] = useState([])
	const [project, setProject] = useState({ label: 'Select Project', value: '' });
	const [categories, setCategories] = useState([])
	const [category, setCategory] = useState({ label: 'Select SR Category', value: '' });

	const handleProjectChange = (project: any) => {
		setProject(project)
		getCategories(project.value)
	}

	const handleCategoryChange = (category: any) => {
		setCategory(category);
	}

	useEffect(() => {
		if (selectProjectOptions.length === 1) {
			setProject({ label: selectProjectOptions[0]['label'], value: selectProjectOptions[0]['value'] })
		}
	}, [selectProjectOptions])

	useEffect(() => {
		if (dateRange !== '') {
			options.reloadMyTable();
			getUserDashboard()
		}
	}, [project, category, dateRange])

	const getProjectList = async () => {
		const url = process.env.REACT_APP_API_URL + 'projects';

		const response = await fetch(url, getRequestOptions());

		const data = await response.json();

		data.data.map((element: any) => {
			element.value = element.id_projects;
			element.label = element.project_name;
			return element;
		})

		if (data.data.length === 1) {
			setSelectProjectOptions(data.data)

		} else {
			var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
			let allProjects = data.data.concat(allProjectsOpt);
			setSelectProjectOptions(allProjects);
		}
	}

	const getCategories = async (idProjects: number) => {

		const url = process.env.REACT_APP_API_URL + 'allSrCategory?idProjects=' + idProjects
		const response = await fetch(url, getRequestOptions())
		const data = await response.json()

		data.map((element: any) => {
			element.value = element.id_sr_categories;
			element.label = element.sr_category_name;
			return element;
		})

		var allCategoriesOpt = [{ id: '', name: '', label: 'All', value: '' }]
		let allCategories = data.concat(allCategoriesOpt);
		setCategories(allCategories);

	}


	const getUserDashboard = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'app/dashboard?idProjects=' + project.value + '&idSrCategories=' + category.value + '&type=' + dateRange + '&customFromDate=' + customDate.customFromDate + '&customToDate=' + customDate.customToDate,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				setDashboardData({
					...dashboardData,
					leadsCreated: resp.leadsCreated,
					highPriorityLeads: resp.highPriorityLeads,
					leadsLastThreeDays: resp.leadsLastThreeDays,
					totalActivity: resp.totalActivity,
					activityLastSevenDays: resp.activityLastSevenDays,
					commentLastSevenDays: resp.commentLastSevenDays,
					totalSales: resp.sales.amount,
					totalPayment: resp.collection,
					totalDue: resp.due,
					invoice: resp.invoice,
					topSellingProduct: resp.topSellingProduct,
				});

				const graphSeries = [{
					name: "Sales",
					data: resp.webgraphSalesData
				}]

				if (dateRange === 'year') {
					graphSeries.push({
						name: "Target",
						data: resp.webgraphTargetData
					})
				}

				setChartData({
					options: {
						chart: {
							id: "basic-bar"
						},
						xaxis: {
							categories: resp.webgraphLabel
						},
						yaxis: {
							labels: {
								formatter: function (val: number) {
									return convertToBDAmount(val);
								}
							}
						}
					},
					series: graphSeries,
				})
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const pendingGoodsColumns = [
		{
			value: 'referenceNo', title: 'PO No', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
				return (
					<>
						<Link to={`/po-details/${row.idPo}`}>
							{row.referenceNo}
						</Link>
					</>
				)
			})
		},
		{ value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
		{ value: 'vendorName', title: 'Vendor', style: { textAlign: 'left' } },
		{ value: 'purchaseDate', title: 'Purchase Date', style: { textAlign: 'left' } },
		{ value: 'itemName', title: 'Item', style: { textAlign: 'left' } },
		{ value: 'quantity', title: 'Order Quantity', style: { textAlign: 'center' } },
		{ value: 'receivedQuantity', title: 'Received Quantity', style: { textAlign: 'center' } },
		{ value: 'dueQuantity', title: 'Due Quantity', style: { textAlign: 'center' } },

	]

	const leadsColumns = [
		{
			value: 'leadName', title: 'lead', style: { textAlign: 'left' }, hasHtmlComponent: true, htmlComponent: ((row: any) => {
				return (
					<>
						<Link to={`/lead-details?idLeads=${row.idLeads}`}>{row.leadName}</Link>
					</>
				)
			})
		},
		{ value: 'assignedToName', title: 'Assigned To', style: { textAlign: 'left' } },
		{ value: 'companyName', title: 'Client', style: { textAlign: 'left' } },
		{ value: 'lastActivity', title: 'Last Activity', style: { textAlign: 'left' } },
	]

	const activityColumns = [
		{ value: 'activityDescription', title: 'Event/Comment', style: { textAlign: 'left' } },
		{
			value: 'leadName', title: 'lead', style: { textAlign: 'left' }, hasHtmlComponent: true, htmlComponent: ((row: any) => {
				return (
					<>
						<Link to={`/lead-details?idLeads=${row.idLeads}`}>{row.leadName}</Link>
					</>
				)
			})
		},
		{ value: 'assignedToName', title: 'Assigned To', style: { textAlign: 'left' } },
		{ value: 'activityStatus', title: 'Status', style: { textAlign: 'center', textTransform: 'capitalize' } },
	]

	const pendingGoodsFilters = {
		referenceNo: {
			filterType: 'like',
			filterValue: '',
		},
		projectName: {
			filterType: 'like',
			filterValue: '',
		},
		vendorName: {
			filterType: 'like',
			filterValue: '',
		},
		purchaseDate: {
			filterType: 'like',
			filterValue: '',
		},
		itemName: {
			filterType: 'like',
			filterValue: '',
		},
		quantity: {
			filterType: 'like',
			filterValue: '',
		},
		receivedQuantity: {
			filterType: 'like',
			filterValue: '',
		},
		dueQuantity: {
			filterType: 'like',
			filterValue: '',
		},
	}

	const leadsFilters = {
		leadName: {
			filterType: 'like',
			filterValue: '',
		},
		assignedToName: {
			filterType: 'like',
			filterValue: '',
		},
		companyName: {
			filterType: 'like',
			filterValue: '',
		},
		lastActivity: {
			filterType: 'like',
			filterValue: '',
		},
	}

	const activityFilters = {
		activityDescription: {
			filterType: 'like',
			filterValue: '',
		},
		leadName: {
			filterType: 'like',
			filterValue: '',
		},
		assignedToName: {
			filterType: 'like',
			filterValue: '',
		},
		activityStatus: {
			filterType: 'like',
			filterValue: '',
		},
	}

	const pendingGoodsAdditionalPostData = {
		idProjects: project.value,
		idSrCategories: category.value,
		dateRange: dateRange,
		customFromDate: customDate.customFromDate,
		customToDate: customDate.customToDate,
	};

	const leadsAdditionalPostData = {
		type: leadType,
		idProjects: project.value,
		dateRange: dateRange,
		customFromDate: customDate.customFromDate,
		customToDate: customDate.customToDate,
	};

	const activityAdditionalPostData = {
		type: activityType,
		idProjects: project.value,
		dateRange: dateRange,
		customFromDate: customDate.customFromDate,
		customToDate: customDate.customToDate,
	};

	const numberFormat = (value: number) => {
		return parseFloat(value.toString()).toLocaleString('en-US', {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	// Function to get the label based on status
	const getStatusLabel = (status: string) => {
		switch (status) {
			case 'approved':
				return <span className="badge badge-success label-inline">Approved</span>;
			case 'denied':
				return <span className="badge badge-danger label-inline">Denied</span>;
			case 'requested':
				return <span className="badge badge-warning label-inline">Requested</span>;
			case 'paid':
				return <span className="badge badge-info label-inline">Paid</span>;
			default:
				return null;
		}
	};

	const expenseColumns = [
		{ title: 'ID', field: 'idAccExpenses', sortable: true, searchable: true, thStyle: { textAlign: "center", width: "5%" }, tdStyle: { textAlign: "center" } },
		{ title: 'Expense Date', field: 'expenseDate', sortable: true, searchable: true },
		{ title: 'Expense Category', field: 'expenseCategory', sortable: true, searchable: true },
		{
			title: 'Requester',
			field: 'userName',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: ((rowValue: any) => {
				return (
					<>
						{(rowValue.userName) ?? ''}
					</>
				)
			})
		},
		{
			title: 'Approver',
			field: 'approverName',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: ((rowValue: any) => {
				return (
					<>
						{(rowValue.approverName) ?? ''}
					</>
				)
			})
		},
		{
			title: 'Project',
			field: 'projectName',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: ((rowValue: any) => {
				return (
					<>
						{rowValue.projectName ?? ''}
					</>
				)
			})
		},
		{
			title: 'Lead',
			field: 'leadName',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: ((rowValue: any) => {
				return (
					<>
						{rowValue.idLeads ? <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>{rowValue.leadName}</a> : ''}
					</>
				)
			})
		},
		{
			title: 'Expense Amount',
			field: 'expenseAmount',
			sortable: true,
			searchable: true,
			tdStyle: { textAlign: "right" },
			hasComponent: true,
			componentValue: ((rowValue: any) => {
				return (
					<>
						{numberFormat(rowValue.expenseAmount)}
					</>
				)
			})
		},
		{
			title: 'Remarks',
			field: 'remarks',
			sortable: true,
			searchable: true
		},
		{
			title: 'Status',
			field: 'latestStatus',
			sortable: true,
			searchable: true,
			hasComponent: true,
			componentValue: ((rowValue: any) => {

				return (
					<>
						{getStatusLabel(rowValue.latestStatus)}
					</>
				);
			}),
		},
	]

	const jwt = () => {
		const myToken = localStorage.getItem('MyToken');
		return (myToken != null) ? JSON.parse(myToken) : null;
	}

	let options = {
		title: '',
		url: process.env.REACT_APP_API_URL + 'dashboard/expenses',
		authorization: 'Bearer ' + jwt(),
		perPage: [10, 20, 50, 100, 500],
		orderBy: 'idAccExpenses',
		orderType: 'desc',
		columnSearch: true,
		reloadMyTable: () => { },
		extraData: {
			"idProjects": project.value,
			"dateRange": dateRange,
			"customFromDate": customDate.customFromDate,
			"customToDate": customDate.customToDate,
		}
	}

	const pendingGoodsUrl = process.env.REACT_APP_API_URL + 'dashboard/pendingGoods'
	const leadsUrl = process.env.REACT_APP_API_URL + 'dashboard/leads'
	const activityUrl = process.env.REACT_APP_API_URL + 'dashboard/activities'

	const [chartData, setChartData] = useState({
		options: {
			chart: {
				id: "basic-bar"
			},
			xaxis: {
				categories: ['0', '1']
			},
			yaxis: {
				labels: {
					formatter: function (val: number) {
						return convertToBDAmount(val);
					}
				}
			}
		},
		series: [
			{
				name: "Amount",
				data: ['0', '1']
			}
		],

	});

	const handleCustomDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCustomDate({ ...customDate, [e.target.name]: e.target.value });
	};

	const showCustomDateData = () => {
		setDateRange('custom')
		options.reloadMyTable();
		getUserDashboard()
	};

	const convertToBDAmount = (label: any) => {
		let labelVal = Number(label);

		if (label < 0) {
			labelVal = label * -1;
		}

		if (labelVal > 10000000) {
			labelVal = (labelVal / 10000000).toFixed(2) + ' Cr';
		}
		if (labelVal > 100000) {
			labelVal = (labelVal / 100000).toFixed(2) + ' Lac';
		}
		if (labelVal > 1000) {
			labelVal = (labelVal / 1000).toFixed(2) + ' K';
		}
		if (label < 0) {
			return '-' + labelVal;
		}
		return labelVal;
	}

	return (
		<>
			<div style={{ background: "none" }} className='card card-custom'>
				<div className='card-header card-header-stretch overflow-auto'>
					<div className='card-title'>
						<h3 className='card-label'>Manager Dashboard</h3>
					</div>
					<div className="card-toolbar">
						<div className='d-flex justify-content-end'>
							<div className='mt-5 mb-2'>
								<select className='form-control' onChange={(event) => handleDateRangeChange(event)}>
									<option value={'year'}>This Year</option>
									<option value={'month'}>This Month</option>
									<option value={''}>Custom</option>
								</select>
							</div>
							{(dateRange === '' || dateRange === 'custom') &&
								<>
									&nbsp;&nbsp;&nbsp;
									<div className='mt-5 mb-2'>
										<input type='date' className='form-control  form-control-sm' name='customFromDate' onChange={handleCustomDateChange}></input>
									</div>
									&nbsp;&nbsp;&nbsp;
									<div className='mt-5 mb-2'>
										<input type='date' className='form-control  form-control-sm' name='customToDate' onChange={handleCustomDateChange}></input>
									</div>
									&nbsp;&nbsp;&nbsp;
									<div className='mt-5 mb-2'>
										<button className='btn-primary form-control  form-control-sm' name='showCustomData' onClick={showCustomDateData}>Show</button>
									</div>
								</>
							}
							&nbsp;&nbsp;&nbsp;
							{/* {JSON.stringify(customDate, null, 2)} */}
							<div className='mt-5 mb-2'>
								<Select
									// defaultValue={selectProjectOptions}
									onChange={handleProjectChange}
									options={selectProjectOptions}
									placeholder={project.label}
									menuPosition="fixed"
									styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
								/>
							</div>
							&nbsp;&nbsp;&nbsp;
							<div className='mt-5 mb-2'>
								<Select
									// defaultValue={selectProjectOptions}
									onChange={handleCategoryChange}
									options={categories}
									placeholder={category.label}
									menuPosition="fixed"
									styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="card-body">

					<div className="row gy-5 g-xl-2">
						<div className='col-10'></div>
						<div className='col-2'>
							{/* <Select
								defaultValue={selectProjectOptions}
								onChange={handleProjectChange}
								options={selectProjectOptions}
								placeholder="Select project"
							/> */}
						</div>
					</div>

					<div className="row gy-5 g-xl-2 mt-4">

						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: leadType === "lastThreeDays" ? "#f3d6ef82" : "" }} className="card h-lg-100">
								<Link to={`reports?type=orderSheetReport&idProjects=${project.value}&fromDate=${fromDate}&toDate=${toDate}&idSrCategories=${category.value}`}>
									<div className="card-body card-lead d-flex justify-content-between align-items-start flex-column" onClick={() => handleClickLeads('lastThreeDays')}>
										{/* <div className="m-2">
											<KTSVG className='svg-icon-danger svg-icon svg-icon-2' path='/media/svg/icons/Communication/Clipboard-list.svg'></KTSVG>
										</div> */}
										<div className="d-flex flex-column my-4">
											<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalSales)}</span>
											<div className="my-1">
												<span className="fw-semibold fs-4 text-gray-400"> Orders </span>
											</div>
										</div>

									</div>
								</Link>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: activityType === "lastSevenDays" ? "#bfdde380" : "" }} className="card h-lg-100">
								<Link to={project.value !== '' ? `reports?type=collectionReport&idProjects=${project.value}&fromDate=${fromDate}&toDate=${toDate}` : "#"}
									onClick={(e) => {
										if (project.value === '') e.preventDefault();
									}}>
									<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column" onClick={() => handleClickActivity('lastSevenDays')}>
										{/* <div className="m-2">
										<KTSVG className='svg-icon-success svg-icon svg-icon-2' path='/media/svg/icons/Shopping/Box3.svg'></KTSVG>
									</div> */}
										<div className="d-flex flex-column my-4">
											<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalPayment)}</span>
											<div className="my-1">
												<span className="fw-semibold fs-4 text-gray-400"> Total Payment </span>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: activityType === "commentsSevenDays" ? "#bfdde380" : "" }} className="card h-lg-100">
								<Link to={`reports?type=dueReport&idProjects=${project.value}&fromDate=${fromDate}&toDate=${toDate}`}>
									<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column" onClick={() => handleClickActivity('commentsSevenDays')}>

										{/* <div className="m-2">
											<KTSVG className='svg-icon-warning svg-icon svg-icon-2' path='/media/svg/icons/Shopping/Price1.svg'></KTSVG>
										</div> */}
										<div className="d-flex flex-column my-4">
											<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.totalDue)}</span>
											<div className="my-1">
												<span className="fw-semibold fs-4 text-gray-400"> Total Due </span>
											</div>
										</div>

									</div>
								</Link>
							</div>
						</div>

						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div className="card h-lg-100">

								<Link to={project.value !== '' ? `reports?type=invoiceHistoryReport&idProjects=${project.value}&fromDate=${fromDate}&toDate=${toDate}` : "#"}
									onClick={(e) => {
										if (project.value === '') e.preventDefault();
									}}>
									<div className="card-body card-lead d-flex justify-content-between align-items-start flex-column" onClick={() => handleClickLeads('')}>
										{/* <div className="m-2">
										<KTSVG className='svg-icon-success svg-icon svg-icon-2' path='/media/svg/icons/Communication/Clipboard-check.svg'></KTSVG>
									</div> */}
										<div className="d-flex flex-column my-4">
											<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(dashboardData.invoice)}</span>
											<div className="my-1">
												<span className="fw-semibold fs-4 text-gray-400"> Invoice Submitted </span>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: leadType === "highPriority" ? "#f3d6ef82" : "" }} className="card h-lg-100">
								<div className="card-body card-lead d-flex justify-content-between align-items-start flex-column" onClick={() => handleClickLeads('highPriority')}>
									{/* <div className="m-2">
										<KTSVG className='svg-icon-primary svg-icon svg-icon-2' path='/media/svg/icons/General/Thunder-move.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{dashboardData.highPriorityLeads}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> High Priority Leads </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-2 mb-xl-10">
							<div style={{ background: activityType === "" ? "#bfdde380" : "" }} className="card h-lg-100">
								<div className="card-body card-activity d-flex justify-content-between align-items-start flex-column" onClick={() => handleClickActivity('')}>
									{/* <div className="m-2">
										<KTSVG className='svg-icon-info svg-icon svg-icon-2' path='/media/svg/icons/Map/Direction2.svg'></KTSVG>
									</div> */}
									<div className="d-flex flex-column my-4">
										<span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{dashboardData.totalActivity}</span>
										<div className="my-1">
											<span className="fw-semibold fs-4 text-gray-400"> Total Activity </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<Row className="gy-5 g-xl-2 mb-3">
						<Col md={6}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										Sales/Target Analysis
									</div>
								</div>
								<div className='card-body card-scroll h-450px'>
									{/* <pre>{JSON.stringify(chartData, null, 2)}</pre> */}
									<Chart
										options={chartData.options}
										series={chartData.series}
										type="line"
										width="700"
									/>
								</div>
							</div>
						</Col>
						<Col md={6}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										Top Selling Products
									</div>
								</div>
								<div className='card-body card-scroll h-450px'>
									{/* <pre>{JSON.stringify(dashboardData.topSellingProduct, null, 2)}</pre> */}
									<Table bordered hover size="sm">
										<thead>

											<tr>
												<th className='text-center'>Product</th>
												<th className='text-center'>Quantity</th>
												<th className='text-center'>Amount</th>
											</tr>
										</thead>
										<tbody>

											{dashboardData.topSellingProduct.map((item: any, index) => {
												return <tr key={'product_' + index}>
													<td>{item.productName + ' ' + item.groupUnitName}</td>
													<td className='text-center'>{Number(item.quantity) + ' ' + item.unitName}</td>
													<td className='text-center'>{convertToBDAmount(item.amount)}</td>

												</tr>
											})}

										</tbody>
									</Table>

								</div>
							</div>
						</Col>
					</Row>

					<Row className="gy-5 g-xl-2">
						<Col md={12}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											List of Expenses Pending Approval
										</span>
									</div>
									<div className="card-toolbar">
									</div>
								</div>

								<div className='card-body'>
									<BackendTable columns={expenseColumns} options={options} />
								</div>
							</div>
						</Col>
						<Col md={12}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											Pending Goods Receive
										</span>
									</div>
									<div className="card-toolbar">
									</div>
								</div>

								<div className='card-body'>
									<DataTable url={pendingGoodsUrl} columns={pendingGoodsColumns} filters={pendingGoodsFilters} additionalPostData={pendingGoodsAdditionalPostData} />
								</div>
							</div>
						</Col>
						<Col md={6}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											Leads
										</span>
									</div>
									<div className="card-toolbar">

									</div>
								</div>

								<div className='card-body'>
									<DataTable url={leadsUrl} columns={leadsColumns} filters={leadsFilters} newData={leadType} additionalPostData={leadsAdditionalPostData} />
								</div>
							</div>
						</Col>
						<Col md={6}>
							<div className='card card-custom'>
								<div className='card-header'>
									<div className="card-title">
										<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
										<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
											Activity
										</span>
									</div>
									<div className="card-toolbar">

									</div>
								</div>

								<div className='card-body'>
									<DataTable url={activityUrl} columns={activityColumns} filters={activityFilters} newData={activityType} additionalPostData={activityAdditionalPostData} />
								</div>
							</div>
						</Col>
					</Row>

				</div>
			</div >

		</>

	)
}

export default DashboardUser
