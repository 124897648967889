import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat } from '../helpers/GlobalHelper';

// Define a type for the sales data
interface CollectionData {
    id_companies: number;
    company_name: string;
    upcoming_30: string;
    upcoming_0_to_15: string;
    upcoming_15_to_30: string;
    past_0_to_15: string;
    past_15_to_30: string;
    past_30_to_45: string;
    past_45: string;
    total_due: string;
};

interface TotalCollectionData {
    upcoming_30: string;
    upcoming_0_to_15: string;
    upcoming_15_to_30: string;
    past_0_to_15: string;
    past_15_to_30: string;
    past_30_to_45: string;
    past_45: string;
    total_due: string;
};

interface CollectionReportProps { }

const CustomerEdgingReport: React.FC<CollectionReportProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const [collectionData, setCollectionData] = useState<CollectionData[]>([]);
    const [totalCollectionData, setTotalCollectionData] = useState<TotalCollectionData[]>([]);
    const [formData, setFormData] = useState({
        idProjects: 0,
    })

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getProjects();
    }, [])

    useEffect(() => {
        if (projects.length > 0) {
            setProject(projects[0]);
            setFormData((prevFormData) => ({
                ...prevFormData,
                idProjects: projects[0].value,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);


    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        if (project) {
            setFormData({ ...formData, idProjects: project.value });
        } else {
            setFormData({ ...formData, idProjects: 0 });
        }
    }

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = event.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    const handleViewReport = async () => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/customer-edging-report', postRequestOptions(formData));
            const data = await response.json();
            if (data.success) {
                setCollectionData(data.data);
                setTotalCollectionData(data.totalData);
            } else {
                setCollectionData([]);
                setTotalCollectionData([]);
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    // const queryParams = new URLSearchParams(formData);

    // Initialize totals
    // let totalSales = 0;

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>
                            </div>
                            {/* <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}invoice_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                                </div>
                            </div> */}
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th className='text-center'>Client</th>
                                <th className='text-center'>Upcoming 30</th>
                                <th className='text-center'>Upcoming 15-30</th>
                                <th className='text-center'>Upcoming 0-15</th>
                                <th className='text-center'>Past 0-15</th>
                                <th className='text-center'>Past 15-30</th>
                                <th className='text-center'>Past 30-45</th>
                                <th className='text-center'>Past 45</th>
                                <th className='text-center'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {collectionData.length > 0 ? collectionData.map((company) => {
                                return (
                                    <React.Fragment key={company.id_companies}>
                                        <tr>
                                            <td>{company.company_name}</td>
                                            <td className='text-end'>{numberFormat(company.upcoming_30)}</td>
                                            <td className='text-end'>{numberFormat(company.upcoming_15_to_30)}</td>
                                            <td className='text-end'>{numberFormat(company.upcoming_0_to_15)}</td>
                                            <td className='text-end'>{numberFormat(company.past_0_to_15)}</td>
                                            <td className='text-end'>{numberFormat(company.past_15_to_30)}</td>
                                            <td className='text-end'>{numberFormat(company.past_30_to_45)}</td>
                                            <td className='text-end'>{numberFormat(company.past_45)}</td>
                                            <td className='text-end'>{numberFormat(company.total_due)}</td>
                                        </tr>
                                    </React.Fragment>
                                );
                            }) : (
                                <tr>
                                    <td colSpan={9} className='text-center'>No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                        {collectionData.length > 0 && (
                            <tfoot>
                                <tr>
                                    <td className='text-end'><strong>Total</strong></td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.upcoming_30)}</td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.upcoming_15_to_30)}</td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.upcoming_0_to_15)}</td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.past_0_to_15)}</td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.past_15_to_30)}</td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.past_30_to_45)}</td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.past_45)}</td>
                                    <td className='text-end'>{numberFormat(totalCollectionData.total_due)}</td>
                                </tr>
                            </tfoot>
                        )}
                    </Table>
                </div>
            </div>
        </>
    );
};

export default CustomerEdgingReport;
