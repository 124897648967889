import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat } from '../helpers/GlobalHelper';
import { useLocation } from "react-router-dom";


// Define a type for the sales data
interface CollectionData {
    idCompanies: number;
    companyName: string;
    invoiceDate: string;
    invoiceId: string;
    invoiceAmount: number;
};

interface CollectionReportProps { }

const InvoiceHistoryReport: React.FC<CollectionReportProps> = (props) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false);
    const [collectionData, setCollectionData] = useState<CollectionData[]>([]);
    const [formData, setFormData] = useState({
        // idProjects: 0,
        // fromDate: '',
        // toDate: '',
        idProjects: query.get('idProjects') ?? '',
        fromDate: query.get('fromDate') ?? '',
        toDate: query.get('toDate') ?? '',
        // idSr: query.get('idSr') ?? '',
    })

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getProjects();
    }, [])

    useEffect(() => {

        if(projects.length>0){
            const projectObj = Number(query.get('idProjects')) ? projects.find((project: any) => project.value === Number(query.get('idProjects'))) : projects[0];
            setProject({
                label: projectObj?.label,
                value: projectObj?.value
            });
            
            setFormData({
                ...formData,
                idProjects: projectObj ? projectObj.value : '',
            });

        }else{
            setProject({ label: 'Select Project', value: '' });
        }

        if (formData.fromDate && formData.toDate) {
            handleViewReport();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item
                })
                setProjects(projectObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleProjectChange = (project: any) => {
        setProject(project);
        if (project) {
            setFormData({ ...formData, idProjects: project.value });
        } else {
            setFormData({ ...formData, idProjects: 0 });
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = async () => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/invoice-history', postRequestOptions(formData));
            const data = await response.json();
            if (data.success) {
                setCollectionData(data.data);
            } else {
                setCollectionData([]);
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const queryParams = new URLSearchParams(formData);

    // Initialize totals
    let totalSales = 0;

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb3" controlId="fromDate">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' value={formData.fromDate} onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="toDate">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' value={formData.toDate} onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}invoice_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th className='text-center'>Party Name</th>
                                <th className='text-center'>Inv. Dt.</th>
                                <th className='text-center'>Inv No</th>
                                <th className='text-center'>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {collectionData.length > 0 ? collectionData.map((company) => {
                                totalSales += Number(company.invoiceAmount);
                                return (
                                    <React.Fragment key={company.idCompanies}>

                                        <tr>
                                            <td>{company.companyName}</td>
                                            <td className='text-center'>{company.invoiceDate}</td>
                                            <td className='text-center'>{company.invoiceId}</td>
                                            <td className='text-end'>{numberFormat(company.invoiceAmount)}</td>
                                        </tr>
                                    </React.Fragment>
                                );
                            }) : (
                                <tr>
                                    <td colSpan={4} className='text-center'>No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                        {collectionData.length > 0 && (
                            <tfoot>
                                <tr>
                                    <td colSpan={3} className='text-end'><strong>Total</strong></td>
                                    <td className='text-end'><strong>{numberFormat(totalSales)}</strong></td>
                                </tr>
                            </tfoot>
                        )}
                    </Table>
                </div>
            </div>
        </>
    );
};

export default InvoiceHistoryReport;
