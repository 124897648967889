import React from 'react';
import { Table, Form  } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions} from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat } from '../helpers/GlobalHelper';
import { useLocation } from "react-router-dom";

interface Rows {
    id_sales_order_rows: Number;
    id_sales_orders: Number;
    id_products: Number;
    quantity: Number;
    unitRate: Number;
    remarks: string;
    productName: string;
    packing: string;
    size: string;
}

// Define a type for the sales data
interface OrderData {
    idSalesOrders: Number;
    salesOrderId: string;
    salesOrderDate: string;
    companyName: string;
    salesPerson: string;
    remarks: string;
    orderAmount: any;
    rows: Rows[];
    company: any;
    lead: any;

};

interface OrderSheetReportProps {

}

const OrderSheetReport: React.FC<OrderSheetReportProps> = (props) => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [loading, setLoading] = useState(false);
    const [orderSheetData, setOrderSheetData] = useState<OrderData[]>([]);
    const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });
    const [showRate, setShowRate] = useState(true);

    const [categories, setCategories] = useState([])
	const [category, setCategory] = useState({ label: 'Select SR Category', value: '' });

    const [formData, setFormData] = useState({
        idProjects: query.get('idProjects') ?? '',
        fromDate: query.get('fromDate') ?? '',
        toDate: query.get('toDate') ?? '',
        idSrCategories: query.get('idSrCategories') ?? '',
        showRate: showRate??false
    });

    useEffect(() => {
        getProjects();
        getCategories();
    }, [])

    useEffect(() => {

        if(projects.length>0){
            const projectObj = Number(query.get('idProjects')) ? projects.find((project: any) => project.value === Number(query.get('idProjects'))) : projects[0];
            setProject({
                label: projectObj?.label,
                value: projectObj?.value
            });
            
            setFormData({
                ...formData,
                idProjects: projectObj ? projectObj.value : '',
            });

        }else{
            setProject({ label: 'Select Project', value: '' });
        }
        
        if(categories.length>0){
            const categoryObj = categories.find((category: any) => category.value === Number(query.get('idSrCategories')));
            setCategory({
                label: categoryObj?.label,
                value: categoryObj?.value
            });
        }else{
            setCategory({ label: 'All SR Category', value: '' });
        }

       
        if (formData.fromDate && formData.toDate) {
            handleViewReport();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projectObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projectObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

    const getCategories = async () => {

		const url = process.env.REACT_APP_API_URL + 'allSrCategory'
		const response = await fetch(url, getRequestOptions())
		const data = await response.json()

		data.map((element: any) => {
			element.value = element.id_sr_categories;
			element.label = element.sr_category_name;
			return element;
		})

		var allCategoriesOpt = [{ id: '', name: '', label: 'All SR Category', value: '' }]
		let allCategories = data.concat(allCategoriesOpt);
		setCategories(allCategories);

	}

    const handleCategoryChange = (category: any) => {
		setCategory(category);
        if(category){
            setFormData({ ...formData, idSrCategories: category.value });
        }else{
            setFormData({ ...formData, idSrCategories: '' });
        }
	}

    const handleProjectChange = (project: any) => {
		setProject(project);
        if(project){
            setFormData({ ...formData, idProjects: project.value });
        }else{
		    setFormData({ ...formData, idProjects: '' });
        }
	}

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
 

    const handleViewReport = async () => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/order-sheet', postRequestOptions(formData));
            const data = await response.json();
           
            if (data.success) {
                setOrderSheetData(data.data);
            } else {
                setOrderSheetData([]);
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const showRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowRate(event.target.checked);
        setFormData({ ...formData, showRate: event.target.checked });
    };

    const totalQuantity = orderSheetData.reduce((total, order) => total + order.rows.reduce((total, item) => total + Number(item.quantity), 0), 0);
    const totalAmount = orderSheetData.reduce((total, order) => total + order.rows.reduce((total, item) => total + (Number(item.quantity)*Number(item.unitRate)), 0), 0);

    const queryParams = new URLSearchParams(formData);

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' value={formData.fromDate} onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' value={formData.toDate} onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label>SR Category</Form.Label>
                                    <Select
                                        value={category}
                                        onChange={handleCategoryChange}
                                        options={categories}
                                        placeholder={category.label}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>                                
                            </div>
                            <div className='col-md-1'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}order_sheet_report_download?` + queryParams.toString()} title='Excel Download'><i className='fas fa-download'></i></a>
                                </div>                                
                            </div>
                            <div className="col-md-2">
                                <Form.Group className="mt-6" controlId="showRate">
                                    <Form.Label></Form.Label>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label="Show Rate"
                                        className="form-switch"
                                        checked={showRate}
                                        onChange={showRateChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>

                    </Card.Text>
                </Card.Body>
            </Card>
           
            <div className='row'>
                <div className='col-12'>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th className='text-center'>Date</th>
                                <th className='text-center'>Order Sheet No</th>
                                <th className='text-center'>Party Name</th>
                                <th className='text-center'>Sales Person</th>
                                <th className='text-center'>Item</th>
                                <th className='text-center'>Package</th>
                                <th className='text-center'>Size</th>
                                <th className='text-center'>Remarks</th>
                                <th className='text-center'>Quantity</th>
                                {showRate && (
                                    <>
                                        <th className='text-center'>Rate</th>
                                        <th className='text-center'>Amount</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {orderSheetData.length > 0 ? (
                                orderSheetData.map((order, slIndex) => (
                                    <React.Fragment key={order.idSalesOrders}>
                                        {order.rows.map((item, index) => (
                                            <tr key={item.idSalesOrders}>
                                                {index === 0 && (
                                                    <>
                                                        <td rowSpan={order.rows.length} className='text-center'>
                                                            {order.salesOrderDate}
                                                        </td>
                                                        <td rowSpan={order.rows.length} className='text-center'>
                                                            {order.salesOrderId==='' ? order.idSalesOrders : order.salesOrderId}
                                                        </td>
                                                        <td rowSpan={order.rows.length} className='text-center'>
                                                            {order.company.companyName}
                                                            {order.company.phones.map((phone: any, index: number) => (
                                                                <div key={index}>{phone.phoneNumber}</div>
                                                            ))}
                                                            {order.company.companyAddress}
                                                        </td>
                                                        <td rowSpan={order.rows.length} className='text-center'>
                                                            {order.salesPerson}
                                                        </td>
                                                    </>
                                                )}
                                                <td>{item.productName}</td>
                                                <td>{item.packing}</td>
                                                <td>{item.size}</td>
                                                <td>{item.remarks ?? ''}</td>
                                                <td className='text-end'>{numberFormat(item.quantity)}</td>
                                                {showRate && (
                                                    <>
                                                        <td className='text-end'>{numberFormat(item.unitRate)}</td>
                                                        <td className='text-end'>{numberFormat(Number(item.quantity)*Number(item.unitRate))}</td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={10} className='text-center'>No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={8} className='text-end'><strong>Total</strong></th>
                                <th className='text-end'><strong>{numberFormat(totalQuantity)}</strong></th>
                                {showRate && (
                                    <>
                                        <th></th>
                                        <th className='text-end'><strong>{numberFormat(totalAmount)}</strong></th>
                                    </>
                                )}                                
                            </tr>
                        </tfoot>

                    </Table>
                </div>
            </div>
        </>
    );
};

export default OrderSheetReport;