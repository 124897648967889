import { Button, Col, Row, Table } from "react-bootstrap-v5";
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import { useEffect, useState } from "react";
import Select from "react-select";
import showLoader from "../helpers/ShowLoader";
import Swal from "sweetalert2";
import React from 'react';
import {numberFormat } from '../helpers/GlobalHelper';  

import { useLocation } from "react-router-dom";
interface LedgerData {
    rawMaterials: any[];
    fgOpeningBalance: number;
    fgTrans: number;
    fgClosingBalance: number;
    fgConsumption: number;
}

export default function CostOfGoodsSoldReport() {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
   
    const [cogsData, setCogsData] = useState<LedgerData>({} as LedgerData);
    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);

    const [formData, setFormData] = useState({
        idProjects: query.get('idProjects') ?? '',
        fromDate: query.get('fromDate') ?? '',
        toDate: query.get('toDate') ?? '',
    })

    useEffect(() => {
        if (selectProjectOptions.length > 0) {
            const project = query.get('idProjects')
                ? selectProjectOptions.find((opt: any) => opt.value === Number(query.get('idProjects')))
                : selectProjectOptions[0];
    
            setSelectedProject(project);
            setFormData({
                ...formData,
                idProjects: project ? project.value : '',
            });
    
            if (formData.fromDate && formData.toDate) {
                getCostOfGoodsSold();
            }
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectProjectOptions]);

    async function getProjectList() {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data.data.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })
        setSelectProjectOptions(data.data);
        setSelectedProject(selectProjectOptions[0]);
    }

    const handleProjectChange = (project: any) => {
        setSelectedProject(project);
        if(project){
            setFormData({ ...formData, idProjects: project.value });
        }else{
		    setFormData({ ...formData, idProjects: '' });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value, "event.target.value");
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    async function getCostOfGoodsSold() {
        showLoader();
        const response = await fetch(process.env.REACT_APP_API_URL + 'reports/cost-of-goods-sold', postRequestOptions(formData))
        const data = await response.json();
        if (data.success) {
            Swal.close();
            setCogsData(data.data);
        } else {
            Swal.close();
            // toast(data.errors, { type: 'error' });
        }
    }

    useEffect(() => {
        getCostOfGoodsSold();
        getProjectList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Outside your component's return statement, initialize a serial number counter
    const queryParams = new URLSearchParams(formData);
    
    const consumptionAmount = cogsData.rawMaterials?.reduce((acc, item) => acc + Number(item.consumptionAmount), 0) || 0;
    const openingRawMaterials = cogsData.rawMaterials?.reduce((acc, item) => acc + Number(item.openingAmount), 0) || 0;
    const closingRawMaterials = cogsData.rawMaterials?.reduce((acc, item) => acc + Number(item.closingAmount), 0) || 0;

    return <div className='card card-custom'>
        <div className='card-header'>
            <div className='card-toolbar'>
                <div className='d-flex justify-content-end align-items-center'>
                    <Select
                        value={selectedProject}
                        className="form-control-sm h-50px w-250px me-3"
                        onChange={handleProjectChange}
                        options={selectProjectOptions}
                        placeholder="Select project"
                    />
                    <Row>
                        <Col className="d-flex align-items-center text-end pe-0">
                            From:
                        </Col>
                        <Col>
                            <input  type="date" className="d-inline form-control form-control-sm" name='fromDate' value={formData.fromDate} onChange={handleChange} />
                        </Col>
                        <Col className="d-flex align-items-center text-end pe-0">
                            To:
                        </Col>
                        <Col>
                            <input type="date" className="d-inline form-control form-control-sm" name='toDate' value={formData.toDate} onChange={handleChange} />
                        </Col>
                    </Row>
                    <Button onClick={() => getCostOfGoodsSold()} variant="primary" className="ms-2 p-2 me-2" size="sm">Refresh</Button>
                    <a className="btn btn-sm btn-primary p-2 ml-2" href={`${process.env.REACT_APP_BACKEND}cost_of_goods_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                </div>
            </div>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col">
                    <div className="trial-balance-container table-responsive">
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th className="text-center" width="50%">Particulars</th>
                                    <th className="text-center" width="50%">Year Ended</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><strong>Raw materials</strong></td>
                                    <td className="text-end"></td>
                                </tr>
                                <tr>
                                    <td><strong> Opening stock</strong></td>
                                    <td className="text-end"></td>
                                </tr>

                                {cogsData.rawMaterials?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="pl-10">{item.ledgerName}</td>
                                        <td className="text-end">{numberFormat(item.openingAmount)}</td>
                                    </tr>
                                ))}

                                <tr>
                                    <td><strong>Purchase:</strong></td>
                                    <td className="text-end"></td>
                                </tr>
                                {cogsData.rawMaterials?.map((item, index) => (
                                    <tr key={'closing-'+index}>
                                        <td className="pl-10">Purchase of {item.ledgerName}</td>
                                        <td className="text-end">{numberFormat(item.transPeriodAmount)}</td>
                                    </tr>
                                ))}

                                <tr>
                                    <td><strong> Closing Stock:</strong></td>
                                    <td className="text-end"></td>
                                </tr>
                                {cogsData.rawMaterials?.map((item, index) => (
                                    <tr key={'closing-'+index}>
                                        <td className="pl-10">Less: Closing Stock of {item.ledgerName}</td>
                                        <td className="text-end">{numberFormat(item.closingAmount)}</td>
                                    </tr>
                                ))}
                               
                                <tr>
                                    <td><strong>Raw & Packing materials Consumption</strong></td>
                                    <td className="text-end"><strong>{numberFormat(consumptionAmount)}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Opening stock of RM & PM</strong></td>
                                    <td className="text-end"><strong>{numberFormat(openingRawMaterials)}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Closing</strong></td>
                                    <td className="text-end"><strong>{numberFormat(closingRawMaterials)}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Opening stock of Finished Goods</strong></td>
                                    <td className="text-end"><strong>{numberFormat(cogsData.fgOpeningBalance)}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Purchase Finished Goods</strong></td>
                                    <td className="text-end"><strong>{numberFormat(cogsData.fgTrans)}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Closing stock of Finished Goods</strong></td>
                                    <td className="text-end"><strong>{numberFormat(cogsData.fgClosingBalance)}</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>COGS</strong></td>
                                    <td className="text-end"><strong>{numberFormat(cogsData.fgConsumption)}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}