import React from 'react';
import { Table, Form  } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions} from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define a type for the company data
interface CompanyData {
    idCompanies: number;
    companyName: string;
    opBalance: number;
    totalSales: number;
    totalInvoicedAmount: number;
    totalPaymentAmount: number;
    totalBalance: number;
};

// Define a type for the sales data
interface SalesData {
    assignedTo: string;
    name: string;
    companies: CompanyData[];
};

interface SalesReportProps {

}

const SalesReport: React.FC<SalesReportProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const [salesData, setSalesData] = useState<SalesData[]>([]);
    const [formData, setFormData] = useState({
        idProjects: '',
        fromDate: '',
        toDate: '',
    });

    const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getProjects();
    }, [])

    useEffect(() => {
        if(projects.length>0){
            setProject(projects[0]);
            setFormData({
                ...formData,
                idProjects: projects[0].value,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects])

    const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projectObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projectObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

    const handleProjectChange = (project: any) => {
		setProject(project);
        if(project){
            setFormData({ ...formData, idProjects: project.value });
        }else{
		    setFormData({ ...formData, idProjects: '' });
        }
	}

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
 

    const handleViewReport = async () => {
        setLoading(true);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/sales', postRequestOptions(formData));
            const data = await response.json();
           
            if (data.success) {
                setSalesData(data.data);
            } else {
                setSalesData([]);
                data.errors.forEach((error: string) => toast.error(error, { position: 'bottom-right', autoClose: 4000 }));
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Outside your component's return statement, initialize a serial number counter
    let slNo = 0;
    const queryParams = new URLSearchParams(formData);

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary w-100 p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>View Report</span>
                                    </button>
                                </div>                                
                            </div>
                            <div className='col-md-2'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary w-100 p-3 mb-2" href={`${process.env.REACT_APP_BACKEND}sales_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                                </div>                                
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
           
            <div className='row'>
                <div className='col-12'>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th className='text-center'>Sl.</th>
                                <th className='text-center'>Client Name</th>
                                <th className='text-center'>Op. Balance</th>
                                <th className='text-center'>Sales</th>
                                <th className='text-center'>Invoice</th>
                                <th className='text-center'>Delivered</th>
                                <th className='text-center'>Balance</th>
                                <th className='text-center'>Sales Person</th>
                            </tr>
                        </thead>
                        <tbody>

                            {salesData.length>0 ? salesData.map((salesperson, slIndex) => (
                                <React.Fragment key={salesperson.assignedTo}>
                                    {salesperson.companies.map((company, index) => {
                                        // Increment the serial number for the first company of each salesperson
                                        slNo++;
                                        return (
                                            <tr key={company.idCompanies}>
                                                <td className='text-center'>{slNo}</td>
                                                <td>{company.companyName}</td>
                                                <td className='text-end'>{numberFormat(company.opBalance)}</td>
                                                <td className='text-end'>{numberFormat(company.totalSales)}</td>
                                                <td className='text-end'>{numberFormat(company.totalInvoicedAmount)}</td>
                                                <td className='text-end'>{numberFormat(company.totalPaymentAmount)}</td>
                                                <td className='text-end'>{numberFormat(company.totalBalance)}</td>
                                                {index === 0 && (
                                                    // Apply rowSpan for the salesperson's name if they have more than one company
                                                    <td rowSpan={salesperson.companies.length}>
                                                        {salesperson.name}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                            )) : (
                                <tr>
                                    <td colSpan={8} className='text-center'>No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default SalesReport;