/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button, Form } from 'react-bootstrap'
import { Col, Row } from 'react-bootstrap-v5';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import showLoader from "../helpers/ShowLoader"
import Select from 'react-select'
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';

interface LeadCreateProps {
	show: boolean;
	handleClose: any;
	id: number;
}

interface InitialState {
	leadName: string;
	idCompanies: string;
	idCompanyContactPersons: string;
	idProjects: string;
	contactPersonName: string;
	address: string;
	estimatedValue: number | null;
	orderFrequencyMonth: number | null;
	emails: string[];
	phones: string[];
}

const LeadCreate: React.FC<LeadCreateProps> = ({ show, handleClose, id }) => {


	const initialState: InitialState = {
		leadName: '',
		idCompanies: '',
		idCompanyContactPersons: '',
		address: '',
		idProjects: '',
		contactPersonName: '',
		estimatedValue: null,
		orderFrequencyMonth: null,
		emails: [],
		phones: [],
	};

	const [companies, setCompanies] = useState([]);
	const [company, setCompany] = useState({ label: 'Select Client', value: '' });

	const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });
	const [formData, setFormData] = useState(initialState);

	useEffect(() => {
		getCompanies();
		getProjects();
	}, [])

	const getCompanies = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/companies',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let companiesObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setCompanies(companiesObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}
	const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projectObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projectObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}


	const handleCompanyChange = (comapny: any) => {
		setCompany(comapny);
		setFormData({ ...formData, idCompanies: comapny?.value });
	}

	const handleProjectChange = (project: any) => {
		setProject(project);
		setFormData({ ...formData, idProjects: project.value });
	}

	const getEmailsData = (data: any) => {
		setFormData({ ...formData, emails: data });
	}

	const getPhonesData = (data: any) => {
		setFormData({ ...formData, phones: data });
	}

	const handleFormChange = (e: any) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};


	const handleFormSubmit = async (event: any) => {
		event.preventDefault()
		showLoader()
		const requestOptions = postRequestOptions(formData)
		try {

			let response = await fetch(
				`${process.env.REACT_APP_API_URL}lead/store`,
				requestOptions
			)

			let respData = await response.json()
			Swal.close();
			if (respData.success === true) {
				handleClose(respData.success)
			} else {
				const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
				Swal.fire({
					icon: 'error',
					title: 'Error',
					html: errorsMessage,
				})
			}
		} catch (error) {
			Swal.close();
			console.log(error, 'catch the hoop')
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose()} size="lg">
			<form onSubmit={handleFormSubmit}>
				<Modal.Header className="py-2" closeButton>
					<Modal.Title>Add new lead</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col className='border-end' md={6}>
							<Form.Group className="mb-3" controlId="leadName">
								<Form.Label>Lead Name<span className="required text-danger"></span></Form.Label>
								<Form.Control onChange={handleFormChange} name="leadName" type="text" placeholder="Enter lead name" />
							</Form.Group>
							<Form.Group className="mb-3" controlId="company">
								<Form.Label>Client<span className="required text-danger"></span></Form.Label>
								<Select
									value={company}
									onChange={handleCompanyChange}
									options={companies}
									menuPosition="fixed"
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="contactPerson">
								<Form.Label>Contact Person</Form.Label>
								<Form.Control type="text" onChange={handleFormChange} name='contactPersonName' placeholder="Enter contact person" />
							</Form.Group>
							<Form.Group className="mb-3" controlId="project">
								<Form.Label>Project<span className="required text-danger"></span></Form.Label>
								<Select
									value={project}
									onChange={handleProjectChange}
									options={projects}
									menuPosition="fixed"
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="estimatedValue">
								<Form.Label>Total Usage Value</Form.Label>
								<Form.Control type="number" onChange={handleFormChange} name='estimatedValue' placeholder="Enter total usage value" />
							</Form.Group>
							<Form.Group className="mb-3" controlId="orderFrequencyMonth">
								<Form.Label>Order Frequency (Month)</Form.Label>
								<Form.Control type="number" onChange={handleFormChange} name='orderFrequencyMonth' placeholder="Enter Order Frequency By Month" />
							</Form.Group>
						</Col>
						<Col md={6}>
							<p>Personal contacts:</p>
							<Form.Group className="mb-3" controlId="email">
								<Form.Label>Email</Form.Label>
								<EmailForm getEmailsData={getEmailsData} emails={formData.emails}></EmailForm>
							</Form.Group>
							<Form.Group className="mb-3" controlId="phone">
								<Form.Label>Phone</Form.Label>
								<PhoneForm getPhonesData={getPhonesData} phones={formData.phones}></PhoneForm>
							</Form.Group>
							<Form.Group className="mb-3" controlId="address">
								<Form.Label>Address</Form.Label>
								<Form.Control type="textarea" name='address' placeholder="Enter address" onChange={handleFormChange} value={formData?.address} />
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className='py-1'>
					<Button type="submit" variant='success' className="btn-sm me-2"><span>  Save</span></Button>
					<Button variant='secondary' size="sm" onClick={() => handleClose()}>
						Cancel
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}

export default LeadCreate
