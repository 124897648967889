import React from 'react';
import { Table, Form  } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions} from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat } from '../helpers/GlobalHelper';

// Define a type for the sales data
interface SalesData {
    userName: string;
    srTotalSales: number;
    months: {
        [month: string]: {
            items: {
                productName: string;
                packing: string;
                size: number;
                productUnitName: string;
                itemUnitName: string;
                deliveredQuantity: number;
                deliveredAmount: number;
                remarks: string;
            }[];
            totalSales: number;
        };
    };
};

interface SalespersonWiseSalesProps {

}

const SalespersonWiseSalesReport: React.FC<SalespersonWiseSalesProps> = (props) => {

    const [loading, setLoading] = useState(false);
    const [salesData, setSalesData] = useState<SalesData[]>([]);
    const [formData, setFormData] = useState({
        idProjects: '',
        idUsers: [],
        fromDate: '',
        toDate: '',
    });

    const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });
    
    const [sr, setSr] = useState();
    const [salesRepresentitives, setSalesRepresentatives] = useState([]);

    useEffect(() => {
        getProjects();
        getAllSalesRepresentative()
    }, [])

     useEffect(() => {
        if(projects.length>0){
            setProject(projects[0]);
            setFormData({
                ...formData,
                idProjects: projects[0].value,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects])

    const getProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'lead/projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let projectObj = resp.data.map((item: any) => {
					item.label = item.name;
					item.value = item.id;
					return item
				})
				setProjects(projectObj);
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}


    const getAllSalesRepresentative = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'getAllSr',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let users = resp.map((item: any) => {
                    item.label = item.first_name + ' ' + item.last_name + ' (' + item.assigned_projects + ')';
                    item.value = item.id;
                    return item
                })
                setSalesRepresentatives(users);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })


    }

    const handleProjectChange = (project: any) => {
		setProject(project);
        if(project){
            setFormData({ ...formData, idProjects: project.value });
        }else{
		    setFormData({ ...formData, idProjects: '' });
        }
	}

    const handleSrChange = (selectedOptions: any[]) => {
        setSr(selectedOptions);
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData({ ...formData, idUsers: selectedValues });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = async () => {
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/salesperson-wise-sales', postRequestOptions(formData));
            const data = await response.json();
           
            if (data.success) {
                setSalesData(data.data);
            } else {
                setSalesData([]);
                for (const i in data.errors) {
                    toast(<strong>{data.errors[i].join(' ')}</strong>, { type: 'error', position: 'bottom-right', autoClose: 4000 });
                }
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const queryParams = new URLSearchParams(formData);

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Select SR<span className='required'></span></Form.Label>
                                    <Select
                                        value={sr}
                                        onChange={handleSrChange}
                                        options={salesRepresentitives}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                        isMulti={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span><i className='fas fa-eye'></i>View Report</span>
                                    </button>
                                    <a className="btn btn-sm btn-primary p-3 ms-3" href={`${process.env.REACT_APP_BACKEND}salesperson_wise_sales_report_download?` + queryParams.toString()}><i className='fas fa-download'></i>Excel Download</a>
                                    <a className="btn btn-sm btn-primary p-3 ms-3" href={`${process.env.REACT_APP_BACKEND}salesperson_wise_sales_report_print?` + queryParams.toString()}><i className='fas fa-print'></i>Print</a>
                                </div>                                
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
           
            <div className='row'>
                <div className='col-12'>
                    {salesData && salesData.length > 0 ? (
                        salesData.map((userData, userIndex) => (
                            <React.Fragment key={userIndex}>
                                {/* Display salesperson name */}
                                <h3>Salesperson: {userData.userName}</h3>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Product</th>
                                            <th>Packing</th>
                                            <th>Sales Qty</th>
                                            <th>Unit</th>
                                            <th>Item Qty</th>
                                            <th>Sales Value</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(userData.months).map((month, monthIndex) => (
                                            <React.Fragment key={monthIndex}>
                                                {userData.months[month].items.map((item, itemIndex) => (
                                                    <tr key={`${userIndex}-${monthIndex}-${itemIndex}`}>
                                                        {/* Display the month only once for the first item of the month */}
                                                        {itemIndex === 0 && (
                                                            <td rowSpan={userData.months[month].items.length}>
                                                                {month}
                                                            </td>
                                                        )}
                                                        <td>{item.productName}</td>
                                                        <td>{item.packing + ' '+item.itemUnitName}</td>
                                                        <td className='text-end'>{numberFormat(item.deliveredQuantity)}</td>
                                                        <td className='text-center'>{item.productUnitName}</td>
                                                        <td className='text-end'>{numberFormat(item.deliveredQuantity * item.size)}</td>
                                                        <td className='text-end'>{numberFormat(item.deliveredAmount)}</td>
                                                        <td>{item.remarks ?? ''}</td>
                                                    </tr>
                                                ))}

                                                {/* Monthly total for the current month */}
                                                {userData.months[month].items.length > 0 && (
                                                    <tr key={`monthly-total-${monthIndex}`}>
                                                        <td colSpan={6} className='text-end'><strong>Monthly Total:</strong></td>
                                                        <td className='text-end'><strong>{numberFormat(userData.months[month].totalSales)}</strong></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}

                                        {/* Grand Total for the current user */}
                                        <tr key={`user-total-${userIndex}`}>
                                            <td colSpan={6} className='text-end'><strong>Grand Total for {userData.userName}:</strong></td>
                                            <td className='text-end'><strong>{numberFormat(userData.srTotalSales)}</strong></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        ))
                    ) : (
                        <div className='text-center'>
                            <p>No data found</p>
                        </div>
                    )}
                </div>

            </div>
        </>
    );
};

export default SalespersonWiseSalesReport;