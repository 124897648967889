/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
// import { Table } from 'react-bootstrap-v5';
import DataTable from '../components/DataTable'
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const MergeEntity = () => {

    const [entity, setEntity] = useState('');
    const [newData, setNewData] = useState({});
    const [units, setUnits] = useState([]);
    const [productGroups, setProductGroups] = useState([]);
    const [firstUnit, setFirstUnit] = useState({ label: 'Select From Unit', value: '' });
    const [secondUnit, setSecondUnit] = useState({ label: 'Select Target Unit', value: '' });
    const [companies, setCompanies] = useState([]);
    const [firstCompany, setFirstCompany] = useState({ label: 'Select From Client', value: '' });
    const [secondCompany, setSecondCompany] = useState({ label: 'Select Target Client', value: '' });
    const [firstProductGroup, setFirstProductGroup] = useState({ label: 'Select From Product Group', value: '' });
    const [secondProductGroup, setSecondProductGroup] = useState({ label: 'Select Target Product Group', value: '' });
    const [firstProduct, setFirstProduct] = useState({ label: 'Select From Product', value: '' });
    const [secondProduct, setSecondProduct] = useState({ label: 'Select Target Product', value: '' });
    const [itemCategories, setItemCategories] = useState([]);
    const [firstItemCategory, setFirstItemCategory] = useState({ label: 'Select From Item Category', value: '' });
    const [secondItemCategory, setSecondItemCategory] = useState({ label: 'Select Target Item Category', value: '' });
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getUnits();
        getCompanies();
        getProducts();
        getProductGroups();
        getItemCategories();
    }, [])

    const getUnits = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'allUnits',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let unitObj = resp.map((item: any) => {
                    item.label = item.unit_name + ' - ID # ' + item.id_units;
                    item.value = item.id_units;
                    return item
                })
                setUnits(unitObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getCompanies = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/companies',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let companiesObj = resp.data.map((item: any) => {
                    item.label = item.name + ' - ID # ' + item.id;
                    item.value = item.id;
                    return item
                })
                setCompanies(companiesObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProducts = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'products?type=direct',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let companiesObj = resp.data.map((item: any) => {
                    item.label = item.product_name + ' (Packing: ' + item.packing + ') - ID # ' + item.id_products;
                    item.value = item.id_products;
                    return item
                })
                setProducts(companiesObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getProductGroups = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'allItems',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let unitObj = resp.map((item: any) => {
                    item.label = item.item_name + ' - ID # ' + item.id_items;
                    item.value = item.id_items;
                    return item
                })
                setProductGroups(unitObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getItemCategories = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'allItemCategories',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let itemCategories = resp.map((item: any) => {
                    item.label = item.category_name;
                    item.value = item.id_item_categories;
                    return item
                })
                setItemCategories(itemCategories);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }
    const handleFirstUnitChange = (unit: any) => {
        setFirstUnit(unit);
    }
    const handleSecondUnitChange = (unit: any) => {
        setSecondUnit(unit);
    }

    const handleFirstCompanyChange = (company: any) => {
        setFirstCompany(company);
    }
    const handleSecondCompanyChange = (company: any) => {
        setSecondCompany(company);
    }

    const handleFirstProductChange = (product: any) => {
        setFirstProduct(product);
    }
    const handleSecondProductChange = (product: any) => {
        setSecondProduct(product);
    }

    const handleFirstProductGroupChange = (productGroup: any) => {
        setFirstProductGroup(productGroup);
    }
    const handleSecondProductGroupChange = (productGroup: any) => {
        setSecondProductGroup(productGroup);
    }

    const handleFirstItemCategoryChange = (itemCategory: any) => {
        setFirstItemCategory(itemCategory);
    }
    const handleSecondItemCategoryChange = (itemCategory: any) => {
        setSecondItemCategory(itemCategory);
    }


    const submitMergeRequest = async (event: any) => {
        event.preventDefault()
        // setSubmitDisable(true);
        // setLoading(true);
        const confirm = await Swal.fire({
            title: 'Are you sure to merge these items??',
            text: "You can't revert theis action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Merge them!',
        })
        if (confirm.value) {
            const postData = {
                entity: entity,
                firstUnit: firstUnit.value,
                secondUnit: secondUnit.value,
                firstCompany: firstCompany.value,
                secondCompany: secondCompany.value,
                firstProduct: firstProduct.value,
                secondProduct: secondProduct.value,
                firstProductGroup: firstProductGroup.value,
                secondProductGroup: secondProductGroup.value,
                firstItemCategory: firstItemCategory.value,
                secondItemCategory: secondItemCategory.value,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}merge-entity`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    setNewData({ id: Date.now() });

                    getUnits();
                    getCompanies();
                    getProducts();
                    setFirstUnit({ label: 'Select From Unit', value: '' });
                    setSecondUnit({ label: 'Select Target Unit', value: '' });
                    setFirstCompany({ label: 'Select From Client', value: '' });
                    setSecondCompany({ label: 'Select Target Client', value: '' });
                    setFirstProduct({ label: 'Select From Product', value: '' });
                    setSecondProduct({ label: 'Select Target Product', value: '' });
                    setFirstProductGroup({ label: 'Select From Product Group', value: '' });
                    setSecondProductGroup({ label: 'Select Target Product Group', value: '' });

                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
                // setSubmitDisable(false);
                // setLoading(false);
            } catch (error) {
                console.log(error, 'catch the hoop')
                // setSubmitDisable(false);
                // setLoading(false);
            }
        }
    }

    const handleEntityChange = (e: any) => {
        setEntity(e.target.value);
    }

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'type', title: 'Merge Type', style: { textAlign: 'left' } },
        { value: 'fromName', title: 'From', style: { textAlign: 'left' } },
        { value: 'targetName', title: 'Target', style: { textAlign: 'left' } },
        { value: 'mergedAt', title: 'Merged At', style: { textAlign: 'left' } },
        { value: 'requesterName', title: 'Merged By', style: { textAlign: 'left' } },
        { value: 'rolledAt', title: 'Rolled Back At', style: { textAlign: 'left' } },
        { value: 'rollerName', title: 'Rolled Back By', style: { textAlign: 'left' } },
        { value: 'status', title: 'Status', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        {row.status === 'Merged' && (row.type === 'Product' || row.type === 'Product Group') &&
                            <button className="btn btn-light-danger btn-sm p-2" onClick={() => rollBackMerge(row.id)}>
                                <KTSVG
                                    path='/media/svg/icons/Navigation/Left 3.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Roll Back
                            </button>
                        }
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        type: {
            filterType: 'like',
            filterValue: '',
        },
        fromName: {
            filterType: 'like',
            filterValue: '',
        },
        targetName: {
            filterType: 'like',
            filterValue: '',
        },
        mergedAt: {
            filterType: 'like',
            filterValue: '',
        },
        requesterName: {
            filterType: 'like',
            filterValue: '',
        },
        rolledAt: {
            filterType: 'like',
            filterValue: '',
        },
        rollerName: {
            filterType: 'like',
            filterValue: '',
        },
        status: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'merge-requests'

    const rollBackMerge = async (id: number) => {


        const confirm = await Swal.fire({
            title: 'Are you sure to Roll Back?',
            text: "You can't revert theis action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Roll Back!',
        })
        if (confirm.value) {

            const requestOptions = postRequestOptions()
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}merge-entity-rollback/${id}`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    setNewData({ id: Date.now() });
                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
                // setSubmitDisable(false);
                // setLoading(false);
            } catch (error) {
                console.log(error, 'catch the hoop')
                // setSubmitDisable(false);
                // setLoading(false);
            }
        }

    };

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/icons/duotune/coding/cod001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Merge Entity
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">

                    <Tabs
                        defaultActiveKey="new-merge"
                        id="uncontrolled-tab-example"
                        className="mb-3 ml-3 mr-3"
                    >
                        <Tab eventKey="new-merge" title="New Merge">
                            {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
                            <form onSubmit={submitMergeRequest}>

                                <div className="form-group row">
                                    <label htmlFor="idUnits" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Entity <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <select className="form-select form-select-sm" id="entity" name="entity" onChange={handleEntityChange} value={entity}>
                                            <option value="">Select Entity</option>
                                            <option value={'Unit'}>Unit</option>
                                            <option value={'Client'}>Client</option>
                                            <option value={'Product'}>Product</option>
                                            <option value={'Product Group'}>Product Group</option>
                                            <option value={'Item Category'}>Item Category</option>
                                        </select>
                                    </div>
                                </div>

                                {entity === 'Unit' &&
                                    <>
                                        <div className="form-group row">
                                            <label htmlFor="idUnits" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>From Unit <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={firstUnit}
                                                    onChange={handleFirstUnitChange}
                                                    options={units}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target Unit  <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={secondUnit}
                                                    onChange={handleSecondUnitChange}
                                                    options={units}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {entity === 'Client' &&
                                    <>
                                        <div className="form-group row">
                                            <label htmlFor="idUnits" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>From Client <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={firstCompany}
                                                    onChange={handleFirstCompanyChange}
                                                    options={companies}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target Client  <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={secondCompany}
                                                    onChange={handleSecondCompanyChange}
                                                    options={companies}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {entity === 'Product' &&
                                    <>
                                        <div className="form-group row">
                                            <label htmlFor="idUnits" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>From Product <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={firstProduct}
                                                    onChange={handleFirstProductChange}
                                                    options={products}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target Product  <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={secondProduct}
                                                    onChange={handleSecondProductChange}
                                                    options={products}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {entity === 'Product Group' &&
                                    <>
                                        <div className="form-group row">
                                            <label htmlFor="idUnits" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>From Product Group <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={firstProductGroup}
                                                    onChange={handleFirstProductGroupChange}
                                                    options={productGroups}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target Product Group <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={secondProductGroup}
                                                    onChange={handleSecondProductGroupChange}
                                                    options={productGroups}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {entity === 'Item Category' &&
                                    <>
                                        <div className="form-group row">
                                            <label htmlFor="idUnits" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>From Item Category <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={firstItemCategory}
                                                    onChange={handleFirstItemCategoryChange}
                                                    options={itemCategories}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Target Item Category <span className="required text-danger"></span></label>
                                            <div className="col-lg-5">
                                                <Select
                                                    value={secondItemCategory}
                                                    onChange={handleSecondItemCategoryChange}
                                                    options={itemCategories}
                                                    menuPosition="fixed"
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {entity !== '' &&
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <input type="submit" className="btn btn-sm btn-success me-2" />
                                            <Link to='/dashboard' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                                        </div>
                                    </div>
                                }

                            </form>
                        </Tab>
                        <Tab eventKey="merge-request" title="Merge List">
                            <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </div >

    )
}

export default MergeEntity
